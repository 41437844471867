import { createContext, useState } from "react";

const PublicationContext = createContext({});

export const PublicationProvider = ({ children }) => {
    const [publicationProvider, setPublicationProvider] = useState('');

    return (
        <PublicationContext.Provider value={{ publicationProvider, setPublicationProvider }}>
            {children}
        </PublicationContext.Provider>
    )
}

export default PublicationContext;