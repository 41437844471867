// 05/05/2023 v0.0.3 - Enmanuelle
import React from "react";
export const ImgWithBigText = (props) => {
  return (
    <section
      id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
      className="imgWithBigText"
    >
      <div className="imgWithMessage-container imgWithBigText-container">
        {props.data.data.src === "" ? (
          <div
            alt={props.data.data.title}
            style={{
              background:
                "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
              width: "100%",
              height: "100%",
              maxHeight: props.data.data.maxHeight ?? "300px",
            }}
          />
        ) : (props.data.data.showVideo ?? false) ? (
          <video
            /* src={props.data.data.src} */
            alt={props.data.data.title}
            id="videoWelcome"
            autoPlay={true}
            loop={true}
            muted
            style={{
              width: "100%",
              height: "100%",
              minHeight: props.data.data.maxHeight ?? "500px",
              maxHeight: props.data.data.maxHeight ?? "300px",
              objectFit: "cover",
              filter: props.data.data.filter ?? "grayscale(50%)",
            }}
          >
            {" "}
            <source src={props.data.data.src} type="video/mp4" />{" "}
          </video>
        ) : (
          <img
            src={props.data.data.src}
            alt={props.data.data.title}
            style={{
              width: "100%",
              height: "100%",
              minHeight: props.data.data.maxHeight ?? "600px",
              maxHeight: props.data.data.maxHeight ?? "600px",
              objectFit:"cover",
              filter: props.data.data.filter ?? "grayscale(30%)"
            }}
            className="imgWithBigText-img"
          />
        )}

        <div
          className="imgWithBigText-centered"
          style={{
            paddingTop: "0px",
          }}
        >
          <div className="imgWithBigText-title">
            <h2
                className="imgWithBigText-title-part1"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.titlePart1
                    ? props.data.data.titlePart1
                    : "",
                }}
            />
            <h2
                className="imgWithBigText-title-part2"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.titlePart2
                    ? props.data.data.titlePart2
                    : "",
                }}
            />
            <h2
                className="imgWithBigText-title-part3"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.titlePart3
                    ? props.data.data.titlePart3
                    : "",
                }}
            />
            <h2
                className="imgWithBigText-title-part4"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.titlePart4
                    ? props.data.data.titlePart4
                    : "",
                }}
            />
          </div>
          <div className="imgWithBigText-subTitle">
            <h2
                className="imgWithBigText-subTitle-part1"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.subTitlePart1
                    ? props.data.data.subTitlePart1
                    : "",
                }}
            />
            <h2
                className="imgWithBigText-subTitle-part2"
                dangerouslySetInnerHTML={{
                    __html: props.data.data.subTitlePart2
                    ? props.data.data.subTitlePart2
                    : "",
                }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
