import React, { useEffect, useState } from "react";
//import Swal from "sweetalert2"
import { /*Link,*/ useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";

import puntoApi from "../custom/axios/js/axios";
import { Header } from "./header";

import data from "../data/data.json";
import useAuth from "../hook/useAuth";
//import useAuth from "../hook/useAuth";
//import { Loading } from "./loading";

//Variables Globales

export const PostulationDetails = () => {
  const location = useLocation();
  const { /*auth,*/ setAuth } = useAuth();
  const dataPage = data.pages.filter(
    (e) => e.pageName === "PostulationDetails"
  )[0].data;
  
  
  const postIdParams = location.search.slice(
    location.search.lastIndexOf("=") + 1,
    location.search.length
  );
  //const { auth/*, setAuth*/ } = useAuth();
  //const authLocal = auth !== undefined ? auth : JSON.parse(localStorage.getItem("auth"));
  const navigate = useNavigate();
  const [postulations, setPostulations] = useState();
  //const [checked, setChecked] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);

  const currency = function (number) {
    return new Intl.NumberFormat("de-DE").format(number);
  };
  // const convertDate = (fecha) => {
  //   const date = new Date(`${fecha} 03:00:00 GMT`);
  //   const dateParse =
  //     ("0" + date.getDate()).slice(-2) +
  //     "-" +
  //     ("0" + (date.getMonth() + 1)).slice(-2) +
  //     "-" +
  //     date.getFullYear();
  //   return dateParse;
  // };
  function convertDate(fecha) {
    const date = new Date(fecha)
    const formattedDate = date.toLocaleDateString("es-CL", {
      day: "numeric",
      month: "long",
      year: "numeric"
    })
    return formattedDate;
  }
  useEffect(()=>{
    setTimeout(()=>{
      if(!localStorage.auth){
          navigate("/");
      }
    },0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  useEffect(() => {
    //setIsLoading(true);
    const urlPublication = "/campBroker/byBroker/v1?adId="+postIdParams;
    // console.log(urlPublication);
    async function fetchApiDetail() {
      let authLocalStorage = localStorage.getItem("auth");
      if(!authLocalStorage){
        return;
      }
      const token = JSON.parse(authLocalStorage).token;
      //token.headers.authorization = "a11a2a22a2";
      try{
        const response = await puntoApi.get(urlPublication, token);
        // console.log(response.status);
        if(response.status!==200){
          localStorage.removeItem("auth");
          setAuth(null);
          sessionStorage.pathAfterLogin=location.pathname+location.search;
          navigate("/login");  
        }
        setPostulations(response.data.data);
      }catch(err){
        message.success("No se encontraron postulaciones");
      }
      //setIsLoading(false);
    }
    fetchApiDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //funcion para mantener estado del id de la publicacion
  /*const idPublication = () => {
    postulations?.map((data) => localStorage.setItem("adId", data.adId));
  };*/

  // acciones de click
  /*const handleClickPostular = (e, checked) => {
    e.preventDefault();
    if (auth.userName) {
      setChecked(checked);
    } else {
      navigate("/login", { state: `${location.pathname}${location.search}` });
      localStorage.setItem("postId", postIdParams);
    }
  };*/
  
  //onChange form postular
  /*const onChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeM2 = (event) => {
    setM2(event.target.value);
  };
  const onChangeValue = (event) => {
    setValue(event.target.value);
  };
  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };*/
  const numberFormat = function(number){
    return new Intl.NumberFormat('de-DE').format(number) 
  };
  const setMayusText = (text) => {
    return text.toUpperCase();
  }
  return (
    <section
      style={{backgroundColor: dataPage.backgroundColor ?? ""}}
      className="publication-detail"
    >
      <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
      <div
        className="dt-postulation-color"
      >
        <div className="postulation-detail-container-summary">
          {postulations?.map((publication, index) => (
            <div key={publication.id || index}>
              <div className="publication-detail-container-summary-operation-first-part postulation-detail-container-summary-operation-first-part-img">
                <div className="publication-detail-container-summary-operation-first-part-img">
                  <img
                    alt="publication-img"
                    src={publication.operationTypeName === "Compra" ? dataPage.iconBuy : dataPage.iconArriend}
                    className="img-fluid postulation-detail-container-summary-operation-first-part-img-image"
                    style={{
                      minHeight: dataPage.minHeightIcon ?? ""
                    }}
                  />
                </div>
                <div className="publication-detail-container-summary-operation-first-part-info">
                  <h3 className="publication-detail-container-summary-operation">{setMayusText(publication.operationTypeName)}</h3>
                  <h1
                    className="publication-detail-container-summary-name"
                    dangerouslySetInnerHTML={{
                      __html: publication.adName ?? ""
                    }}
                  />
                  <h4 className="publication-detail-container-summary-description">
                    <p className="publication-detail-container-summary-description-text">{publication.countryName}, {publication.cityName}</p>
                    <p className="publication-detail-container-summary-description-text">{numberFormat (publication.adM2From)} m² a { numberFormat(publication.adM2To) } m²</p>
                    <p className="publication-detail-container-summary-description-text">{publication.adZone}</p>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="postulation-detail-container-postulation">
          {postulations?.map((postulation, index) =>
            !postulation.statusCanEditBrokerApply ? (
              <div key={postulation.id || index}>
                <div
                  className="dt-postulation-postu"
                >
                  <h2 className="dt-postulation-postu-title">{dataPage.titlePostulation}</h2>
                  <p
                    className="dt-postulation-descrip"
                  >
                    {postulation.adDescription}
                  </p>
                  {/*<p
                    className="dt-postulation-descrip"
                  >
                    <span className="dt-postulation-postu-title-arrow">{"> "}</span>
                    <span className="d-block">{postulation.adDescription}</span>
                  </p>*/}
                  <div className="dt-postulation-information-item">
                    <img
                      alt="img-information dt-postulation-information-item-img"
                      src={dataPage.imgAddress}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? "",
                        maxHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelAddress}</p>
                      <p className="dt-postulation-information-item-info">{postulation.postAddress}</p>
                    </div>
                  </div>
                  <div className="dt-postulation-information-separator"></div>
                  <div className="dt-postulation-information-item">
                    <img
                      alt="img-information"
                      src={dataPage.imgM2}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelM2}</p>
                      <p className="dt-postulation-information-item-info">{currency (postulation.postM2)}</p>
                    </div>
                  </div>
                  <div className="dt-postulation-information-separator"></div>
                  <div className="dt-postulation-information-item">
                    <img
                      alt="img-information"
                      src={dataPage.imgDate}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelDate}</p>
                      <p className="dt-postulation-information-item-info">{convertDate(postulation.postDate)}</p>
                    </div>
                  </div>
                  <div className="dt-postulation-information-separator"></div>
                  <div className="dt-postulation-information-item">
                    <img
                      alt="img-information"
                      src={dataPage.imgDescription}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelDescription}</p>
                      <p className="dt-postulation-information-item-info">{postulation.postDescriptionWithFormat}</p>
                    </div>
                  </div>
                  <div className="dt-postulation-information-separator"></div>
                  <div className="dt-postulation-information-item dt-postulation-information-item-last">
                    <img
                      alt="img-information"
                      src={dataPage.imgStatus}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelStatus}</p>
                      <p className="dt-postulation-information-item-info">{postulation.statusNameApply}</p>
                    </div>
                  </div>
                </div>
                <div className="dt-postulation-value">
                  <div className="dt-postulation-information-item">
                    <img
                      alt="img-information"
                      src={dataPage.imgValue}
                      style={{
                        minHeight: dataPage.minHeightIconDetail ?? ""
                      }}
                      className="img-fluid dt-postulation-information-item-img"
                    />
                    <div className="dt-postulation-information-item-container-text">
                      <p className="dt-postulation-information-item-title">{dataPage.labelValue}</p>
                      <p className="dt-postulation-information-item-info">${currency(postulation.postValue)}</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )
          )}
          <div className="publication-properties-details-container-button">
            <button
              className = "publication-properties-details-button"
              onClick={(e)=>{
                navigate("/postulations");
              }}
            >
              {dataPage.buttonBack}
            </button>                
          </div>
        </div>
        
      </div>
    </section>
  );
};
