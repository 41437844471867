
//29/05/2023 v3.0.0 - Enmanuelle
import React, { useEffect, useState } from "react";
import {/* Link,*/ useNavigate } from "react-router-dom";
import { message } from "antd";
import { Header } from "./header";

import data from "../data/data.json";
import Swal from "sweetalert2";
import puntoApi from "../custom/axios/js/axios";
import { Loading } from "./loading";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2';
//import { isValidPhoneNumber } from "react-phone-number-input";


export const Register = () => {
  const navigate = useNavigate();
  const dataLogin = data.pages.filter((e) => e.pageName === "Register")[0].data;
  const dataHeader = data.pages.filter((e) => e.pageName === "Header")[0];
  dataHeader.data.backgroundcolor="#0d1e2e";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [brokerName, setBrokerName] = useState("");
  const [brokerType, setBrokerType] = useState([]);
  const [brokerTypeId, setBrokerTypeId] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState([]);
  const [cityFiltered, setCityFiltered] = useState([]);
  const [citySelected, setCitySelected] = useState('');
  const [countrySelected, setCountrySelected] = useState('');
  const [country, setCountry] = useState([]);
  //const [setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //   var email_analizado = [/^([^]+)@(\w+).(\w+)$/].exec(email);
  //   var [,nombre,servidor,dominio] = email_analizado;

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };
  const onChangeName = (event) => {
    setName(event.target.value);
  };
  const onChangePhone = (event) => {
    setPhone(event);
    //console.log(phone);
  };
  const onChangeBrokerName = (event) => {
    setBrokerName(event.target.value);
  };
  const onChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeCity = (event) => {
    setCitySelected(event.target.value);
  };
  const onChangeCountry = (event) => {
    const selectedCountry = event.target.value;
    setCountrySelected(selectedCountry);
    const filteredCities = city.filter((c) => c.countryId === selectedCountry);
    setCityFiltered(filteredCities);
  };  
  const onChangeBrokerTypeId = (event) => {
    setBrokerTypeId(event.target.value);
  }
  /*const serverEmail = (email) => {
    const lastIndex = email.lastIndexOf("@");
    return email.slice(lastIndex + 1, email.length);
  };*/
  /*const success = () => {
    message.success("Has ingresado correctamente");
  };*/
  const error = () => {
    message.error("Ha ocurrido un error, verifica tu email y/o contraseña");
  };

  useEffect(() => {

    puntoApi.get("/camp/filters/v1")
      .then((response) => {
        // console.log("Datos Respuesta /camp/filters/v1",response.data);
        const responseData = response.data?.data[0]; // Accede al primer elemento del array
        setBrokerType(responseData?.brokerType || []);
        setCity(responseData?.city || []);
        setCountry(responseData?.country || []);
      })
      .catch((err) => {
        console.error("Error al obtener los datos:", err);
      });

  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    //if(isValidPhoneNumber(phone))
    try {
      /*const config = {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              email + ":GEODART:EMAIL:::" + serverEmail(email) + ":" + password
            ).toString("base64"),
        },
      };*/
      const body ={
        
          userName:name,
          userEmail:email,
          userPhone: phone,
          userAddress:address,
          brokerName:brokerName,
          brokerType:brokerTypeId,
          countryId:countrySelected,
          cityId:citySelected,
          userPassword:password,
      
      }
      setIsLoading(true);

      const response = await puntoApi.post("/account/v1/", body);
      // const data = response?.data?.data;
      // console.log("Datos a insertar",data);
      if (response.data.isSuccessful === true) {
        Swal.fire(
          "Solicitud exitosa!",
          "Ahora debes esperar la confirmación de tu cuenta",
          "success"
        ) 
        navigate("/login");
        
      } else {
        
        error();
      }
    } catch (err) {
      if (!err?.response) {
        message.error("No Server Response");
      } else if (err.response?.status === 400) {
        message.error("Missing Username or Password");
      } else if (err.response?.status === 401) {
        message.error("Unauthorized");
      } else {
        message.error("Login Failed");
      }
    }
    /*else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'El número de teléfono no es válido!',
      })
    }*/
  };



  return (
    <div
      className="register-container"
      style={{
        backgroundImage: "url('"+dataLogin.backgroundImg+"')",
        position: "relative",
      }}
    >
      <Header data={dataHeader} />
      <div className="register-content">
        {isLoading ?
        <Loading />
        :
        ""
        }
        <div className="register-form">
          <div className="fadeIn register-img-logo">
            <img
              src={dataLogin.img}
              className="img-fluid"
              alt="Geogart-Icon"
              style={{
                maxHeight: dataLogin.maxHeight ?? "60px"
              }}
            />
          </div>
          <h2 className="register-title">{dataLogin.titlePage}</h2>
          <form onSubmit={(e) => handleLogin(e)} className="register-form-object">
            <input
              type="text"
              id="nameFormRegister"
              className="register-input-all-width register-input-color-placeholder"
              name="login"
              placeholder={dataLogin.placeHolderName}
              value={name}
              onChange={onChangeName}
              required
            />
            <div className="register-container-two-input">
              <input
                type="text"
                id="emailFormRegister"
                className="register-input-half-width-left register-input-color-placeholder"
                name="email"
                placeholder="Email"
                value={email}
                onChange={onChangeEmail}
                required
              />
              <PhoneInput
                placeholder={dataLogin.placeHolderPhone}
                value={phone}
                className="register-input-half-width-right register-input-color-placeholder"
                onChange={onChangePhone}
                inputProps={{
                  required: true,
                  autoFocus: false,
                  name: "phone"
                }}
                country={dataLogin.countryIdDefault ?? "cl"}
                preferredCountries={dataLogin.countryPreferredDefault ?? ["cl", "ar"]}
              />
            </div>
            
            <div className="register-container-two-input">
              <input
                type="text"
                id="nameBrokerFormRegister"
                className="register-input-half-width-left register-input-color-placeholder"
                name="nameBroker"
                placeholder="Nombre Broker"
                value={brokerName}
                onChange={onChangeBrokerName}
                required
              />
            
              <select
                id="selectBrokerTypeRegister"
                className="register-input-half-width-right register-input-color-placeholder register-input-half-width-select"
                name="brokerType"
                value={brokerTypeId}
                onChange={onChangeBrokerTypeId}
                required
              >
                <option value="" disabled>
                  {dataLogin.placeHolderTypeBroker || "Selecciona un tipo de broker"}
                </option>
                {brokerType.map((type) => (
                  <option key={type.brokerTypeId} value={type.brokerTypeId}>
                    {type.brokerTypeName}
                  </option>
                ))}
              </select>
            </div>
            <div className="register-container-two-input register-container-two-input-country">
              <select
                name="country"
                id="countryFormRegister"
                className="register-input-half-width-left register-input-color-placeholder register-input-half-width-select"
                onChange={onChangeCountry}
                value={countrySelected}
                required
              >
                <option value="" disabled>
                  {dataLogin.placeHolderCountry || "Selecciona un país"}
                </option>
                {country.map((countryItem) => (
                  <option key={countryItem.countryId} value={countryItem.countryId}>
                    {countryItem.countryName}
                  </option>
                ))}
              </select>
              <select
                name="city"
                id="cityFormRegister"
                className="register-input-half-width-right register-input-color-placeholder register-input-half-width-select"
                onChange={onChangeCity}
                value={citySelected}
                required
              >
                <option value="" disabled>
                  {dataLogin.placeHolderCity || "Selecciona una ciudad"}
                </option>
                {cityFiltered.map((cityItem) => (
                  <option key={cityItem.cityId} value={cityItem.cityId}>
                    {cityItem.cityName}
                  </option>
                ))}
              </select>
            </div>

            <input
              type="text"
              id="addressForm"
              className="register-input-all-width register-input-color-placeholder"
              name="login"
              placeholder={dataLogin.placeHolderAddress}
              value={address}
              onChange={onChangeAddress}
              required
            />
            <input
              type="password"
              id="passwordFormRegister"
              className="register-input-all-width register-input-color-placeholder register-input-password"
              name="password"
              placeholder={dataLogin.placeHolderPassword}
              value={password}
              onChange={onChangePassword}
              required
            />
            <div className="register-container-buttons">
              <button className="register-button register-button-register-account" value="Log In">
                {dataLogin.buttonRegister}
              </button>
              <button className="register-button register-button-have-account" value="Log In" onClick={()=>{navigate("/login")}}>
                {dataLogin.buttonHaveAccount}
              </button>
            </div>
          </form>
        </div>
        <p className="register-text-down">{dataLogin.textDown}</p>
      </div>
    </div>
  );
};
