// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useLayoutEffect } from "react";
import { Header } from "./header";
import data from "../data/data.json";
// import {data.pagesWhatWeDo} from "../data/data.json";
import { PointsWithContent } from "./pointsWithContent";
import { IconsWithDetail } from "./iconsWithDetail";
import { TwoSectionDetail } from "./twoSectionDetail";
import { ImgWithBigText } from "./imgWithBigText";
import { Clients } from "./clients";
import { FooterGeogart } from "./footerGeogart";
import { TextWithImage } from "./textWithImage";
export const WhatWeDo = (props) => {
    const paginas=[];
    const dataHeader = data.pages.filter((e) => e.pageName === "Header")[0];
    dataHeader.data.backgroundcolor = "#0f1c2d"
    //console.log(data.pagesWhatWeDo);
    for(let i=0; i<data.pagesWhatWeDo.length; i++){
        //console.log(data.pagesWhatWeDo[i]);
        if(data.pagesWhatWeDo[i].pageName==="PointsWithContent"){
            paginas.push({pagina: <PointsWithContent data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="IconsWithDetail"){
            paginas.push({pagina: <IconsWithDetail data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="TwoSectionDetail"){
            paginas.push({pagina: <TwoSectionDetail data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="ImgWithBigText"){
            paginas.push({pagina: <ImgWithBigText data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="Clients"){
            paginas.push({pagina: <Clients data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="FooterGeogart"){
            paginas.push({pagina: <FooterGeogart data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }else if(data.pagesWhatWeDo[i].pageName==="TextWithImage"){
            paginas.push({pagina: <TextWithImage data={data.pagesWhatWeDo[i]} key={Math.random()}/>, order: data.pagesWhatWeDo[i].order})
        }
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    //console.log(paginas);
    return (
        <div className="geoInteligencia">
            <Header data={dataHeader} />
            <div>
                {paginas
                .sort((a, b) => a.order - b.order)
                .map((e) => e.pagina)}
            </div>
        </div>
    );
};
