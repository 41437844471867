import { useEffect } from "react";
export const Clients = (props) => {
    const detectScrollClients = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemTitleUp=document.getElementById("clients-title-container");
        if(!elemTitleUp.classList.contains("animation_showed")){
            if(isVisible){
                elemTitleUp.classList.remove("animate__fadeOutLeft");
                elemTitleUp.classList.add("animate__fadeInLeft");
                elemTitleUp.classList.add("animation_showed");
            }else{
                elemTitleUp.classList.remove("animate__fadeInLeft");
                elemTitleUp.classList.add("animate__fadeOutLeft");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollClients);
        return () => {
            window.removeEventListener("scroll", detectScrollClients);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const moveSlider = () => {
        let container = document.querySelector(".clients-items");
        let prevButton = document.querySelector(".clients-items-arrow-left");
        let nextButton = document.querySelector(".clients-items-arrow-right");
        let itemWidth = document.querySelector(".clients-items-item").offsetWidth;
        let visibleItems = Math.floor(container.offsetWidth / itemWidth);
        let totalItems = document.querySelectorAll(".clients-items-item").length;
        let currentPosition = 0;
      
        container.style.width = itemWidth * totalItems + "px";
      
        prevButton.addEventListener("click", function() {
            if (currentPosition > 0) {
                currentPosition--;
            }else{
                //currentPosition = totalItems - visibleItems;
            }
            container.style.transform = 'translateX(-' + currentPosition * itemWidth + 'px)';
        });
        nextButton.addEventListener("click", function() {
            if (currentPosition < totalItems - visibleItems) {
                currentPosition++;
            }else{
            //currentPosition = 0;
                
            }
            container.style.transform = "translateX(-" + currentPosition * itemWidth + "px)";
            /*if(currentPosition < totalItems - visibleItems){
                container.appendChild(container.firstElementChild);
                container.style.transition = 'transform 0.4s ease-out'; 
            }*/
        });
    }
    useEffect(()=>{
        moveSlider();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return(
        <section
            id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ''}
            style={{
                backgroundColor: props.data.data.backgroundcolor ?? ""
            }}
            className="clients"
        >
            <div className="clients-container">
                <div
                    className="clients-title-container animate__animated animate__fadeInLeft clients-animation-duration"
                    id={"clients-title-container"}
                >
                    <h2 className="clients-title">
                        <span
                            className="clients-title-text"
                            style={{
                                fontSize: props.data.data.sizeFontTitle ?? "",
                                fontFamily: props.data.data.fontFamilyTitle ?? "",
                                color: props.data.data.colorTitle ?? "",
                                backgroundColor: props.data.data.backgroundcolorTitle ?? "",
                            }}
                        >
                            {props.data.data.title}
                        </span>
                    </h2>
                    <p
                        className="clients-title-container-paragraph"
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.paragraph
                            ? props.data.data.paragraph
                            : "",
                        }}
                        style={{
                            fontSize: props.data.data.fontSizeParagraph ?? "",
                            fontFamily: props.data.data.fontFamilyParagraph ?? "",
                        }}
                    />
                </div>
                <div className="clients-items-container">
                    <i className="bx bx-chevron-left clients-items-arrow-left"></i>
                    <div className="clients-items">
                        {
                            props.data.data.items.map((d,i)=>(
                                <div className="clients-items-item" key={"clients-items-item"+i.toString()}>
                                    <img
                                        src={d.imgProduct}
                                        alt={d.title}
                                        className="clients-items-item-img img-fluid"
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <i className="bx bx-chevron-right clients-items-arrow-right"></i>
                </div>
            </div>
        </section>
    );
}