// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
export const LinesWithContent = (props) => {
const navigate = useNavigate();
  //localStorage.pageLoad=true;
    let windowWidth = window.innerWidth;
    const [imgBackground, setImgBackground] = useState((windowWidth < 500) ? props.data.data.srcMobile : ((windowWidth < 1080) ? props.data.data.srcIpad : props.data.data.src));
    const detectResizeLinesWithContent = () => {
      let windowWidth = window.innerWidth;
      setImgBackground((windowWidth < 500) ? props.data.data.srcMobile : ((windowWidth < 1080) ? props.data.data.srcIpad : props.data.data.src));
    }
    const detectScrollLinesWithContent = () => {
        /*if(!elem){
          return;
        }*/
        //let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        //let rectElem=elem.getBoundingClientRect();
        //let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        /*let elemLogo=document.getElementById("linesWithContent-img-logo");
        let elemButtonLeftSide=document.getElementById("imgWithMessage-info-down-leftSide");
        let elemButtonRightSide=document.getElementById("imgWithMessage-info-down-rightSide");
        let elemText1=document.getElementById("imgWithMessage-text1");
        if(!elemLogo.classList.contains("animation_showed")){
          if(isVisible){
            elemLogo.classList.remove("animate__fadeOut");
            elemLogo.classList.add("animate__fadeIn");
            elemText1.classList.remove("animate__fadeOut");
            elemText1.classList.add("animate__fadeIn");
            elemButtonLeftSide.classList.remove("animate__fadeOut");
            elemButtonLeftSide.classList.add("animate__fadeIn");
            elemButtonRightSide.classList.remove("animate__fadeOut");
            elemButtonRightSide.classList.add("animate__fadeIn");
            elemLogo.classList.add("animation_showed");
          }else{
              elemLogo.classList.remove("animate__fadeIn");
              elemLogo.classList.add("animate__fadeOut");
              elemText1.classList.remove("animate__fadeIn");
              elemText1.classList.add("animate__fadeOut");
              elemButtonLeftSide.classList.remove("animate__fadeIn");
              elemButtonLeftSide.classList.add("animate__fadeOut");
              elemButtonRightSide.classList.remove("animate__fadeIn");
              elemButtonRightSide.classList.add("animate__fadeOut");
          }
        }*/
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollLinesWithContent);
        window.addEventListener("resize", detectResizeLinesWithContent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollLinesWithContent);
            window.removeEventListener("resize", detectResizeLinesWithContent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
    <section
        id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
        style={{
            marginTop: props.data.data.marginTop ?? "",
            marginBottom: props.data.data.marginTBottom ?? "",
            paddingTop: props.data.data.paddingTop ?? "80px",
            paddingBottom: "0px",
            position: "relative",
            backgroundImage: "url("+imgBackground+")",
            backgroundRepeat: "round",
            height: "100vh"
        }}
        className="lines-with-content"
    >
      <div className="lines-with-content-container-component">
        <div className="lines-with-content-container">
          {(props.data.data.showDivBackground || props.data.data.src === "") ? (
            <div
              className="Aux"
              alt={props.data.data.title}
              style={{
                backgroundColor: "transparent",
                
                maxHeight: props.data.data.maxHeight ?? "800px",
                minHeight: props.data.data.minHeight ?? "800px",
                border: "none"
              }}
            />
          ) : (props.data.data.showVideo ?? false) ? (
            <video
              /* src={props.data.data.src} */
              alt={props.data.data.title}
              id="videoWelcome"
              autoPlay={true}
              loop={true}
              muted
              style={{
                width: "100%",
                height: "100%",
                minHeight: props.data.data.maxHeight ?? "500px",
                maxHeight: props.data.data.maxHeight ?? "300px",
                objectFit: "cover",
                filter: props.data.data.filter ?? "grayscale(50%)",
              }}
            >
              {" "}
              <source src={props.data.data.src} type="video/mp4" />{" "}
            </video>
          ) : (
            <img
              src={""}
              alt={props.data.data.title}
              className="lines-with-content-img-background"
              style={{
                width: "100%",
                height: "100%",
                minHeight: props.data.data.maxHeight ?? "500px",
                maxHeight: props.data.data.maxHeight ?? "500px",
                filter: props.data.data.filter ?? "grayscale(50%)",
                objectFit: "cover"
              }}
            />
          )}

          <div
            className="imgWithMessage-centered"
            style={{
              /*backgroundImage:
                "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
              borderRadius: "15px",
              marginLeft: "0px", */
              paddingTop: "0px",
            }}
          >
            <img
              className="img-fluid imgWithMessage-img-logo animate__animated"
              id="linesWithContent-img-logo"
              src={props.data.data.srcImgLogo}
              alt="img-with-message-logo"
              style={{
                maxHeight: props.data.data.maxHeightImgLogo ?? ""
              }}
            />
            <h2
              className="animate__animated"

            >
              {props.data.data.title}
            </h2>
            <p
              id={"imgWithMessage-text1"}
              className="animate__animated imgWithMessage-text1"
              dangerouslySetInnerHTML={{
                __html: props.data.data.text1
                ? props.data.data.text1
                : "",
              }}
              style={{
                fontFamily: props.data.data.fontFamilyText1 ?? "",
                fontSize: props.data.data.fontSizeText1 ?? "",
              }}
            />
            <p className="animate__animated animate__fadeInUp">
              {props.data.data.text2 ?? ""}
            </p>
            {props.data.data.buttonHref === "" ? (
              ""
            ) : (
              <a
                href={props.data.data.buttonHref}
                className="btn-get-started animate__animated scrollto imgWithMessage-btn"
              >
                {props.data ? props.data.data.buttonText : ""}
              </a>
            )}
          </div>
          <div className="imgWithMessage-container-text-down">
            <span>{props.data.data.textDown1}</span>
            <br/>
            <span>{props.data.data.textDown2}</span>
          </div>
          {/*info-down desktop*/}
          <div className="imgWithMessage-info-down imgWithMessage-info-down-desktop">
            {
              props.data.data.infoDown ?
              <div className="imgWithMessage-info-down-container">
                <div className="imgWithMessage-info-down-leftSide animate__animated" id="imgWithMessage-info-down-leftSide">
                  <div>
                    <a href={props.data.data.infoDown.buttonHrefLeft}>
                    <button
                      onClick={()=>{navigate(props.data.data.infoDown.buttonHrefLeft)}}
                      className="imgWithMessage-info-down-button imgWithMessage-info-down-button-leftSide"
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                        fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                      }}
                    >
                      {props.data.data.infoDown.buttonTextLeft}
                    </button>
                    </a>
                    <p
                      className="imgWithMessage-info-down-text"
                      dangerouslySetInnerHTML={{
                        __html: props.data.data.infoDown.textLeft
                        ? props.data.data.infoDown.textLeft
                        : "",
                      }}
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText ?? "",
                      }}
                    />
                  </div>
                </div>
                <div className="imgWithMessage-info-down-imgCenter">
                  <div className="linesWithContent-info-down-imgCenter-container">
                      <img
                          alt="imgWithMessage-info-down-imgCenter-part1"
                          src={props.data.data.infoDown.srcImgCenterPart1}
                          className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-part1"
                      />
                      <img
                          alt="imgWithMessage-info-down-imgCenter-part2"
                          src={props.data.data.infoDown.srcImgCenterPart2}
                          className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-part2"
                      />
                      <img
                          alt="imgWithMessage-info-down-imgCenter-combined"
                          src={props.data.data.infoDown.srcImgCenterCombined}
                          className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-combined"
                      />
                  </div>
                  <div className="imgWithMessage-info-down-imgCenter-texts">
                    <p
                      className="imgWithMessage-info-down-imgCenter-text1"
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText1DownImg ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText1DownImg ?? "",
                      }}
                    >
                      {props.data.data.infoDown.text1DownImg}
                    </p>
                    <p
                      className="imgWithMessage-info-down-imgCenter-text2"
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText2DownImg ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText2DownImg ?? "",
                      }}
                    >
                      {props.data.data.infoDown.text2DownImg}
                    </p>
                  </div>
                </div>
                <div className="imgWithMessage-info-down-rightSide animate__animated" id="imgWithMessage-info-down-rightSide">
                  <div>
                    <a href={props.data.data.infoDown.buttonHrefRight}>
                      <button
                        onClick={()=>{navigate(props.data.data.infoDown.buttonHrefRight)}}
                        className="imgWithMessage-info-down-button imgWithMessage-info-down-button-rightSide"
                        style={{
                          fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                          fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                        }}
                      >
                        {props.data.data.infoDown.buttonTextRight}
                      </button>
                    </a>
                    <p
                      className="imgWithMessage-info-down-text"
                      dangerouslySetInnerHTML={{
                        __html: props.data.data.infoDown.textRight
                        ? props.data.data.infoDown.textRight
                        : "",
                      }}
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText ?? "",
                      }}
                    />
                  </div>
                </div>
              </div>
              :
              ""
            }
          </div>
          {/*info-down mobile*/}
          <div className="imgWithMessage-info-down imgWithMessage-info-down-mobile">
            {
              props.data.data.infoDown ?
              <div className="imgWithMessage-info-down-container-mobile">
                <div className="imgWithMessage-info-down-imgCenter">
                  <div className="linesWithContent-info-down-imgCenter-container">
                      <div className="linesWithContent-info-down-imgCenter-container-aux">
                        <img
                            alt="imgWithMessage-info-down-imgCenter-part1"
                            src={props.data.data.infoDown.srcImgCenterPart1}
                            className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-part1"
                        />
                        <img
                            alt="imgWithMessage-info-down-imgCenter-part2"
                            src={props.data.data.infoDown.srcImgCenterPart2}
                            className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-part2"
                        />
                        <img
                          alt="imgWithMessage-info-down-imgCenter-combined"
                          src={props.data.data.infoDown.srcImgCenterCombined}
                          className="img-fluid linesWithContent-imgCenter-container linesWithContent-imgCenter-container-combined"
                        />
                      </div>
                  </div>
                  <div className="imgWithMessage-info-down-imgCenter-texts">
                    <p
                      className="imgWithMessage-info-down-imgCenter-text1"
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText1DownImg ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText1DownImg ?? "",
                      }}
                    >
                      {props.data.data.infoDown.text1DownImg}
                    </p>
                    <p
                      className="imgWithMessage-info-down-imgCenter-text2"
                      style={{
                        fontFamily: props.data.data.infoDown.fontFamilyText2DownImg ?? "",
                        fontSize: props.data.data.infoDown.fontSizeText2DownImg ?? "",
                      }}
                    >
                      {props.data.data.infoDown.text2DownImg}
                    </p>
                  </div>
                </div>
                <div className="imgWithMessage-info-down-downSides-mobile">
                  <div className="imgWithMessage-info-down-leftSide-mobile animate__animated" id="imgWithMessage-info-down-leftSide">
                    <div>
                      <a href={props.data.data.infoDown.buttonHrefLeft}>
                        <button
                          onClick={()=>{navigate(props.data.data.infoDown.buttonHrefLeft)}}
                          className="imgWithMessage-info-down-button-mobile imgWithMessage-info-down-button-leftSide"
                          style={{
                            fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                            fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                          }}
                        >
                          {props.data.data.infoDown.buttonTextLeft}
                        </button>
                      </a>
                      <p
                        className="imgWithMessage-info-down-text"
                        dangerouslySetInnerHTML={{
                          __html: props.data.data.infoDown.textLeft
                          ? props.data.data.infoDown.textLeft
                          : "",
                        }}
                        style={{
                          fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                          fontSize: props.data.data.infoDown.fontSizeText ?? "",
                        }}
                      />
                    </div>
                  </div>
                  <div className="imgWithMessage-info-down-rightSide-mobile animate__animated" id="imgWithMessage-info-down-rightSide">
                    <div>
                      <a href={props.data.data.infoDown.buttonHrefRight}>
                        <button
                          onClick={()=>{navigate(props.data.data.infoDown.buttonHrefRight)}}
                          className="imgWithMessage-info-down-button-mobile imgWithMessage-info-down-button-rightSide"
                          style={{
                            fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                            fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                          }}
                        >
                          {props.data.data.infoDown.buttonTextRight}
                        </button>
                      </a>
                      <p
                        className="imgWithMessage-info-down-text"
                        dangerouslySetInnerHTML={{
                          __html: props.data.data.infoDown.textRight
                          ? props.data.data.infoDown.textRight
                          : "",
                        }}
                        style={{
                          fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                          fontSize: props.data.data.infoDown.fontSizeText ?? "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              ""
            }
          </div>
          <div className="lines-with-content-img-down-container">
              {
                  props.data.data.srcImgDown ?
                  <img
                      alt="lines-with-content-img-down"
                      src={props.data.data.srcImgDown}
                      className="img-fluid lines-with-content-img-down"
                  />
                  :
                  ""
              }
          </div>
        </div>
      </div>
    </section>
  );
};
