import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonWithArrow } from "./buttonWithArrow";
import { useEffect } from "react";
export const OurAdvices = (props) => {
    const navigate=useNavigate();
    const detectScrollOurAdvices = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        if(isVisible){
            for(let i=0;i<props.data.data.items.length;i++){
                let elemTitle=document.getElementById("ourAdvices-container-item-centered-title " + i.toString());
                let elemText=document.getElementById("ourAdvices-container-item-centered-text " + i.toString());
                let elemBtn=document.getElementById("ourAdvices-container-item-centered-btn " + i.toString());
                elemTitle.classList.remove("animate__fadeOutDown");
                elemTitle.classList.add("animate__fadeInDown");
                elemText.classList.remove("animate__fadeOutUp");
                elemText.classList.add("animate__fadeInUp");
                elemBtn.classList.remove("animate__fadeOutUp");
                elemBtn.classList.add("animate__fadeInUp");
            }
        }else{
            for(let i=0;i<props.data.data.items.length;i++){
                let elemTitle=document.getElementById("ourAdvices-container-item-centered-title " + i.toString());
                let elemText=document.getElementById("ourAdvices-container-item-centered-text " + i.toString());
                let elemBtn=document.getElementById("ourAdvices-container-item-centered-btn " + i.toString());
                elemTitle.classList.remove("animate__fadeInDown");
                elemTitle.classList.add("animate__fadeOutDown");
                elemText.classList.remove("animate__fadeInUp");
                elemText.classList.add("animate__fadeOutUp");
                elemBtn.classList.remove("animate__fadeInUp");
                elemBtn.classList.add("animate__fadeOutUp");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollOurAdvices);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollOurAdvices);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
            style={{
                marginBottom: props.data.data.marginTBottom ?? "",
                backgroundColor: props.data.data.backgroundcolor ?? "",
                paddingBottom: "0px",
            }}
        >
            {
                props.data.data.title ?
                <div class="ourAdvices-title">
                    <p
                        class="ourAdvices-title-p"
                        style={{
                            backgroundColor: props.data.data.backgroundcolorTitle ?? "",
                            color: props.data.data.colorTitle ?? ""
                        }}
                    >
                        {props.data.data.title}</p>
                </div>
                :
                ""
            }
            <div class="ourAdvices-container">
                {
                    props.data ? props.data.data.items.map((item,i)=>(
                        <div class="ourAdvices-container-item imgWithMessage-container">
                            <img
                                src={item.img}
                                alt={item.title}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    maxHeight: props.data.data.maxHeight ?? "480px",
                                    maxWidth: props.data.data.maxWidth ?? "420px",
                                    objectFit: "cover",
                                    
                                }}
                            />
                            <div
                                class="ourAdvices-container-item-centered"
                                style={{
                        /*             backgroundImage:
                                    "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
                                    borderRadius: "15px",
                                    marginLeft: "0px", */
                                    
                                }}
                                >
                                <h2
                                    id={"ourAdvices-container-item-centered-title " + i.toString()}
                                    class="animate__animated animate__fadeInDown ourAdvices-container-item-centered-title"
                                    style={{
                                        color: item.colorTitle ? item.colorTitle : "",
                                    }}
                                >
                                    {item.title}
                                </h2>
                                <p
                                    id={"ourAdvices-container-item-centered-text " + i.toString()}
                                    class="animate__animated animate__fadeInUp ourAdvices-container-item-centered-text"
                                    style={{
                                        color: item.colorText ? item.colorText : "",
                                    }}
                                >
                                    
                                    <span>{item.text ?? ""}</span> 
                                </p>
                                
                                {item.buttonText === "" ? (
                                    ""
                                ) : (
                                    <btn
                                        onClick={()=>{navigate("/register")}}
                                        style={{
                                            backgroundColor: props.data.data.backgroundcolorButton ?? ""
                                        }}
                                        id={"ourAdvices-container-item-centered-btn " + i.toString()}
                                        class="btn-get-started animate__animated animate__fadeInUp scrollto ourAdvices-container-item-centered-btn"
                                    >
                                        {item.textButton}
                                    </btn>
                                )}
                                </div>
                        </div>
                    )) : ""
                }
            </div>
            <br></br>
            <div>
                {
                    props.data.data.dataButtonArrow ?
                    <ButtonWithArrow data={
                        {
                            title: "ButtonArrow",
                            href: props.data.data.dataButtonArrow.href,
                            pageName: "ButtonWithArrow",
                            showInMenu: false,
                            showInMain: true,
                            data:props.data.data.dataButtonArrow
                        }
                    }/>
                    :
                    ""
                }
            </div>
        </section>
    );
};
