/*
ver íconos en: https://icon-sets.iconify.design/bx/timer/
*/
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from 'react';
export const OurOfferTime = (props) => {
    const [paragraphNoHighlightedFirstPart, setParagraphNoHighlightedFirstPart] = useState(window.innerWidth > 500 ? props.data.data.paragraphNoHighlightedFirstPart : props.data.data.paragraphNoHighlightedFirstPartMobileVersion)
    const [paragraphNoHighlightedSecondPart, setParagraphNoHighlightedSecondPart] = useState(window.innerWidth > 500 ? props.data.data.paragraphNoHighlightedSecondPart : props.data.data.paragraphNoHighlightedSecondPartMobileVersion)
    const detectResizeOfferTime = () => {
        let widthScreen = window.innerWidth;
        if(widthScreen<500){
            setParagraphNoHighlightedFirstPart(props.data.data.paragraphNoHighlightedFirstPartMobileVersion);
            setParagraphNoHighlightedSecondPart(props.data.data.paragraphNoHighlightedSecondPartMobileVersion);
        }else{
            setParagraphNoHighlightedFirstPart(props.data.data.paragraphNoHighlightedFirstPart);
            setParagraphNoHighlightedSecondPart(props.data.data.paragraphNoHighlightedSecondPart);
        }
    }
    const detectScrollOurOfferTime = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemContainer=document.getElementById("our-offer-time-container");
        let elemBtn=document.getElementById("our-offer-time-button");
        if(isVisible){
            elemBtn.classList.remove("animate__fadeOutLeft");
            elemBtn.classList.add("animate__fadeInLeft");
            elemContainer.classList.remove("animate__fadeOut");
            elemContainer.classList.add("animate__fadeIn");
            /*for(let i=0;i<props.data.data.itemsList.length;i++){
                let elemItem=document.getElementById("our-offer-time-items-item " + i.toString());
                elemItem.classList.remove("animate__fadeOutRight");
                elemItem.classList.add("animate__fadeInRight");
            }*/
        }else{
            elemBtn.classList.remove("animate__fadeInLeft");
            elemBtn.classList.add("animate__fadeOutLeft");
            elemContainer.classList.remove("animate__fadeIn");
            elemContainer.classList.add("animate__fadeOut");
            /*for(let i=0;i<props.data.data.itemsList.length;i++){
                let elemItem=document.getElementById("our-offer-time-items-item " + i.toString());
                elemItem.classList.remove("animate__fadeInRight");
                elemItem.classList.add("animate__fadeOutRight");
            }*/
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollOurOfferTime);
        window.addEventListener("resize", detectResizeOfferTime);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollOurOfferTime);
            window.removeEventListener("resize", detectResizeOfferTime);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const navigate = useNavigate();
    return (
        <section id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ''} class="our-offer-time"
            style={{
                backgroundImage: props.data.data.backgroundImage ? props.data.data.backgroundImage :
                props.data.data.backgroundcolorFirstPart ?
                "linear-gradient(180deg, "+ props.data.data.backgroundcolorFirstPart +" 0%, "+props.data.data.backgroundcolorSecondPart+" 40%)": "",
                
            }}
        >
            <div 
                id="our-offer-time-container"
                class="container our-offer-time-container animate__animated animate__fadeIn"
            >
                <div class="section-title">
                    <h2 class="our-offer-time-title">
                        <span
                            style={{
                                color: props.data.data.colorTitleHighlighted ?? ""
                            }}
                        >
                            {props.data ? props.data.data.titleHighlighted : ''}
                        </span>
                        <span
                            style={{
                                color: props.data.data.colorTitleNoHighlighted ?? ""
                            }}
                        >
                            {props.data ? props.data.data.titleNoHighlighted : ''}
                        </span>
                    </h2>
                    <p class="our-offer-time-paragraph">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: paragraphNoHighlightedFirstPart
                                ? paragraphNoHighlightedFirstPart
                                : "",
                            }}
                            style={{
                                color: props.data.data.colorParagraphNoHighlighted ?? ""
                            }}
                        />
                        
                        <br></br>
                        <span
                            class="our-offer-time-paragraph-highlighted"
                            style={{
                                color: props.data.data.colorParagraphHighlighted ?? ""
                            }}
                        >
                            {props.data.data.paragraphHighlighted}
                        </span>
                        <br></br>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: paragraphNoHighlightedSecondPart
                                ? paragraphNoHighlightedSecondPart
                                : "",
                            }}
                            style={{
                                color: props.data.data.colorParagraphNoHighlighted ?? ""
                            }}
                        />
                        
                        <span
                            class="our-offer-time-paragraph-word-highlighted"
                            style={{
                                color: props.data.data.colorWordHighlightedFirstPart ?? ""
                            }}
                        >
                            {props.data.data.wordHighlightedFirstPart}
                        </span>
                        <span
                            class="our-offer-time-paragraph-word-highlighted"
                            style={{
                                color: props.data.data.colorWordHighlightedSecondPart ?? ""
                            }}
                        >
                            {props.data.data.wordHighlightedSecondPart}.
                        </span>
                    </p>
                </div>
                <div class="our-offer-time-items">
                    {props.data ? props.data.data.itemsList.map((d, i) => (
                    <div 
                        class={"our-offer-time-items-item" /*"our-offer-time-items-item animate__animated animate__fadeInRight" */}
                        id={"our-offer-time-items-item " + i.toString()}
                    >
                        {
                            i % 2 === 0 ?
                            <img
                            alt={d}
                            src={props.data.data.imgPushPin}
                            height={"30px"}
                            width={"24px"}
                            />
                            :
                            <img
                                alt={d}
                                src={props.data.data.imgArrowRight}
                                height={"40px"}
                                width={"30px"}
                                />
                        }
                        <p
                            class="our-offer-time-items-item-text"
                            style={{
                                fontWeight: i % 2 !== 0 ? 700 : "",
                                fontSize: i % 2 !== 0 ? props.data.data.fontSizeSecondItems ?? "14px" : props.data.data.fontSizeFirstItems ?? "19px",
                                marginRight : i % 2 !== 0 ? "20px" : "0px"
                            }}
                        >
                            {d}
                        </p>
                    </div>
                    )) : 'Loading...'}
                    
                </div>
                <br></br>
                <div class="our-offer-time-button-container">
                    {
                        props.data.data.textButton ?
                        <button
                            id="our-offer-time-button"
                            class="our-offer-time-button animate__animated animate__fadeInLeft"
                            style={{
                                color: props.data.data.textButtonColor ? props.data.data.textButtonColor : "",
                                backgroundColor: props.data.data.backgroundcolorButton ?? ""
                            }}
                            onClick={()=>{navigate("/publications")}}
                        >
                            <div class="our-offer-time-button-contain">
                                {/*<i class={props.data.data.iconButton + " our-offer-time-button-icon"}></i>*/}
                                <Icon icon="material-symbols:location-on" color="white" width="80" height="80" />
                                <span class="our-offer-time-button-contain-text">{props.data.data.textButton}</span>
                            </div>
                        </button>
                        :
                        <div></div>
                    }
                </div>
            </div>
        </section>
    )
}