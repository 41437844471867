// 27/04/2023 v0.0.3 - Enmanuelle - agregar imagen logo y botones a los costados
// 30/01/20223 v0.0.2 - Srodriguez - junta versión de mostrar video hecha por RArce en fecha sin determinar
// 03/01/20223 v0.0.1 - 1st version - Roberto
import React from "react";
import { useNavigate } from "react-router-dom";
export const ImgWithMessage = (props) => {
  const navigate = useNavigate();
  return (
    <section
      id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
      style={{
        marginTop: props.data.data.marginTop ?? "",
        marginBottom: props.data.data.marginTBottom ?? "",
        paddingTop: props.data.data.paddingTop ?? "80px",
        paddingBottom: "0px",
        position: "relative",
      }}
    >
      <div className="imgWithMessage-container">
        {props.data.data.src === "" ? (
          <div
            alt={props.data.data.title}
            style={{
              background:
                "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
              width: "100%",
              height: "100%",
              maxHeight: props.data.data.maxHeight ?? "300px",
            }}
          />
        ) : (props.data.data.showVideo ?? false) ? (
          <video
            /* src={props.data.data.src} */
            alt={props.data.data.title}
            id="videoWelcome"
            autoPlay={true}
            loop={true}
            muted
            style={{
              width: "100%",
              height: "100%",
              minHeight: props.data.data.maxHeight ?? "500px",
              maxHeight: props.data.data.maxHeight ?? "300px",
              objectFit: "cover",
              filter: props.data.data.filter ?? "grayscale(50%)",
            }}
          >
            {" "}
            <source src={props.data.data.src} type="video/mp4" />{" "}
          </video>
        ) : (
          <img
            src={props.data.data.src}
            alt={props.data.data.title}
            style={{
              width: "100%",
              height: "100%",
              minHeight: props.data.data.maxHeight ?? "500px",
              maxHeight: props.data.data.maxHeight ?? "500px",
              objectFit: "",
              filter: props.data.data.filter ?? "grayscale(50%)",
            }}
          />
        )}

        <div
          className="imgWithMessage-centered"
          style={{
/*             backgroundImage:
              "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))",
            borderRadius: "15px",
            marginLeft: "0px", */
            paddingTop: "0px",
          }}
        >
          <img
            className="img-fluid imgWithMessage-img-logo fadeIn"
            src={props.data.data.srcImgLogo}
            alt="img-with-message-logo"
            style={{
              maxHeight: props.data.data.maxHeightImgLogo ?? ""
            }}
          />
          <h2
            className="animate__animated animate__fadeInDown"

          >
            {props.data.data.title}
          </h2>
          <p
            className="animate__animated animate__fadeInUp imgWithMessage-text1"
            dangerouslySetInnerHTML={{
              __html: props.data.data.text1
              ? props.data.data.text1
              : "",
            }}
            style={{
              fontFamily: props.data.data.fontFamilyText1 ?? "",
              fontSize: props.data.data.fontSizeText1 ?? "",
            }}
          />
          <p className="animate__animated animate__fadeInUp">
            {props.data.data.text2 ?? ""}
          </p>
          {props.data.data.buttonHref === "" ? (
            ""
          ) : (
            <a
              href={props.data.data.buttonHref}
              className="btn-get-started animate__animated animate__fadeInUp scrollto imgWithMessage-btn"
            >
              {props.data ? props.data.data.buttonText : ""}
            </a>
          )}
        </div>
        <div className="imgWithMessage-container-text-down">
          <span>{props.data.data.textDown1}</span>
          <br/>
          <span>{props.data.data.textDown2}</span>
        </div>
        <div className="imgWithMessage-info-down">
          {
            props.data.data.infoDown ?
            <div className="imgWithMessage-info-down-container">
              <div className="imgWithMessage-info-down-leftSide">
                <button
                  onClick={()=>{navigate(props.data.data.infoDown.buttonHrefLeft)}}
                  className="imgWithMessage-info-down-button imgWithMessage-info-down-button-leftSide"
                  style={{
                    fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                    fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                  }}
                >
                  {props.data.data.infoDown.buttonTextLeft}
                </button>
                <p
                  className="imgWithMessage-info-down-text"
                  dangerouslySetInnerHTML={{
                    __html: props.data.data.infoDown.textLeft
                    ? props.data.data.infoDown.textLeft
                    : "",
                  }}
                  style={{
                    fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                    fontSize: props.data.data.infoDown.fontSizeText ?? "",
                  }}
                />
              </div>
              <div className="imgWithMessage-info-down-imgCenter">
                <img
                  alt="imgWithMessage-info-down-imgCenter"
                  src={props.data.data.infoDown.srcImgCenter}
                  style={{
                    maxHeight: props.data.data.infoDown.maxHeightImgCenter ?? "",
                    maxWidth: props.data.data.infoDown.maxWidthImgCenter ?? ""
                  }}
                  className="img-fluid imgWithMessage-info-down-imgCenter-img"
                />
                <div className="imgWithMessage-info-down-imgCenter-texts">
                  <p
                    className="imgWithMessage-info-down-imgCenter-text1"
                    style={{
                      fontFamily: props.data.data.infoDown.fontFamilyText1DownImg ?? "",
                      fontSize: props.data.data.infoDown.fontSizeText1DownImg ?? "",
                    }}
                  >
                    {props.data.data.infoDown.text1DownImg}
                  </p>
                  <p
                    className="imgWithMessage-info-down-imgCenter-text2"
                    style={{
                      fontFamily: props.data.data.infoDown.fontFamilyText2DownImg ?? "",
                      fontSize: props.data.data.infoDown.fontSizeText2DownImg ?? "",
                    }}
                  >
                    {props.data.data.infoDown.text2DownImg}
                  </p>
                </div>
              </div>
              <div className="imgWithMessage-info-down-rightSide">
                <button
                  onClick={()=>{navigate(props.data.data.infoDown.buttonHrefRight)}}
                  className="imgWithMessage-info-down-button imgWithMessage-info-down-button-rightSide"
                  style={{
                    fontFamily: props.data.data.infoDown.fontFamilyButton ?? "",
                    fontSize: props.data.data.infoDown.fontSizeButton ?? "",
                  }}
                >
                  {props.data.data.infoDown.buttonTextRight}
                </button>
                <p
                  className="imgWithMessage-info-down-text"
                  dangerouslySetInnerHTML={{
                    __html: props.data.data.infoDown.textRight
                    ? props.data.data.infoDown.textRight
                    : "",
                  }}
                  style={{
                    fontFamily: props.data.data.infoDown.fontFamilyText ?? "",
                    fontSize: props.data.data.infoDown.fontSizeText ?? "",
                  }}
                />
              </div>
            </div>
            :
            ""
          }
        </div>
      </div>
    </section>
  );
};
