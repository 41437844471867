// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export const PointsWithContent = (props) => {
    //const navigate = useNavigate();
    //localStorage.pageLoad=true;
    let windowWidth = window.innerWidth;
    const [imgBackground, setImgBackground] = useState((windowWidth < 500) ? props.data.data.srcMobile : ((windowWidth < 1080) ? props.data.data.srcIpad : props.data.data.src));
    const detectResizePointsWithContent = () => {
        let windowWidth = window.innerWidth;
        setImgBackground((windowWidth < 500) ? props.data.data.srcMobile : ((windowWidth < 1080) ? props.data.data.srcIpad : props.data.data.src));
    }
    const detectScrollPointsWithContent = () => {
        /*
        if(!elem){
            return;
        }
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemLogo;
        let elemLeftSide=document.getElementById("pointsWithContent-leftSide");
        elemLeftSide.classList.add("animation_showed");
        if(!elemLeftSide.classList.contains("animation_showed")){
            if(props.data.data.srcImgLogo){
                elemLogo=document.getElementById("pointsWithContent-img-logo");
            }
            if(isVisible){
                if(props.data.data.srcImgLogo){
                    elemLogo.classList.remove("animate__fadeOut");
                    elemLogo.classList.add("animate__fadeIn");
                }
                elemLeftSide.classList.remove("animate__fadeOut");
                elemLeftSide.classList.add("animate__fadeInUp");
                elemLeftSide.classList.add("animation_showed");
            }else{
                if(props.data.data.srcImgLogo){
                    elemLogo.classList.remove("animate__fadeIn");
                    elemLogo.classList.add("animate__fadeOut");
                }
                elemLeftSide.classList.remove("animate__fadeInUp");
                elemLeftSide.classList.add("animate__fadeOut");
            }
        }*/
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollPointsWithContent);
        window.addEventListener("resize", detectResizePointsWithContent);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollPointsWithContent);
            window.removeEventListener("resize", detectResizePointsWithContent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    //console.log(props);
    const showImgRight = () => {
        let elemImgRight = document.getElementById("pointsWithContent-img-rightSide"+props.data.href);
        elemImgRight.style.visibility="visible";
        elemImgRight.classList.add("pointsWithContent-img-rightSide");
    }
    return (
    <section
        id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
        style={{paddingTop:"0px"}}
        className="pointsWithContent"
    >
    <div className="pointsWithContent-container-component">
      <div className="imgWithMessage-container pointsWithContent-container">
        {props.data.data.src === "" ? (
          <div
            className="Aux"
            alt={props.data.data.title}
            style={{
              backgroundColor: "transparent",
              
              maxHeight: props.data.data.maxHeight ?? "800px",
              minHeight: props.data.data.minHeight ?? "800px",
              objectFit: "none",
              position: "relative"
            }}
          />
        ) : (props.data.data.showVideo ?? false) ? (
          <video
            /* src={props.data.data.src} */
            alt={props.data.data.title}
            id="videoWelcome"
            autoPlay={true}
            loop={true}
            muted
            style={{
              width: "100%",
              height: "100%",
              minHeight: props.data.data.maxHeight ?? "500px",
              maxHeight: props.data.data.maxHeight ?? "300px",
              objectFit: "cover",
              filter: props.data.data.filter ?? "grayscale(50%)",
              position: "relative"
            }}
          >
            {" "}
            <source src={props.data.data.src} type="video/mp4" />{" "}
          </video>
        ) : (
          <img
            src={imgBackground}
            alt={props.data.data.title}
            className="pointsWithContent-img-background"
            style={{
              width: "100%",
              height: "100vh",
              
              /*filter: props.data.data.filter ?? "grayscale(50%)",*/
              position: "relative",
              objectFit: "cover"
            }}
          />
        )}

        <div
            className="pointsWithContent-centered animate__animated pointsWithContent-animation-duration"
            style={{
                paddingTop: "0px",
            }}
        >
            <div
                className={"pointsWithContent-leftSide" + (props.data.data.isImgRightPositionRelative ? " pointsWithContent-img-leftSide-absolute" : "")}
                id="pointsWithContent-leftSide"
            >
                <div
                    className="pointsWithContent-leftSide-container"
                    style={{
                        maxWidth: props.data.data.maxWidthText ?? ""
                    }}
                >
                    <div className="pointsWithContent-leftSide-container-firstPart">
                    {
                        props.data.data.srcImgLogo ?
                        <img
                            className="img-fluid imgWithMessage-img-logo animate__animated"
                            id="pointsWithContent-img-logo"
                            src={props.data.data.srcImgLogo}
                            alt="img-with-message-logo"
                            style={{
                                maxHeight: props.data.data.maxHeightImgLogo ?? "",
                                marginBottom: "30px"
                            }}
                        />
                        :
                        ""
                    }
                    <h2
                        className="pointsWithContent-title animate__animated"
                        id="pointsWithContent-title"
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.title
                            ? props.data.data.title
                            : "",
                        }}
                        style={{
                            fontFamily: props.data.data.fontFamilyTitle ?? "",
                            fontSize: props.data.data.fontSizeTitle ?? "",
                            color: props.data.data.colorTitle ?? ""
                        }}
                    />
                    <h3
                        className="pointsWithContent-subTitle animate__animated"
                        id="pointsWithContent-subTitle"
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.subTitle
                            ? props.data.data.subTitle
                            : "",
                        }}
                        style={{
                            fontFamily: props.data.data.fontFamilySubTitle ?? "",
                            fontSize: props.data.data.fontSizeSubTitle ?? "",
                        }}
                    />
                    <p
                        className={"animate__animated pointsWithContent-paragraph" +(props.data.data.fontSizeParagraph ? " isWhatWeDo" : "")
                                + (props.data.data.stopAnimation ? " pointsWithContent-paragraph-bigger-mobile" : "")}
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.paragraph
                            ? props.data.data.paragraph
                            : "",
                        }}
                        style={{
                            fontFamily: props.data.data.fontFamilyParagraph ?? "",
                            fontSize: props.data.data.fontSizeParagraph ?? "",
                        }}
                    />
                    </div>
                    <div className={"pointsWithContent-btn-container" + (props.data.data.showButtonOnlyMobile ? " pointsWithContent-btn-container-show" : "")}>
                        {props.data.data.buttonHref === "" ? (
                            ""
                        ) : (
                            <Link
                            to={props.data.data.buttonHref}
                            className="btn-get-started animate__animated scrollto pointsWithContent-btn"
                            style={{
                                marginTop: props.data.data.showButtonDown ? "30px" : ""
                            }}
                            >
                            {props.data ? props.data.data.buttonText : ""}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={"pointsWithContent-rightSide" + (props.data.data.stopAnimation ? " img-with-message-rightSide-stop-animation": "")
            + (props.data.data.isWhatWeDo ? " pointsWithContent-img-rightSide-whatWeDo": "")}
                style={{
                    backgroundImage: "url('"+ (props.data.data.srcImgBackgroundRight ?? "") + "')",
                    backgroundSize: "cover"
                }}
            >
                {
                    props.data.data.srcImgRight ?
                    <img
                        className={"img-fluid animate__animated" + (props.data.data.isImgRightPositionRelative ? " pointsWithContent-img-rightSide-absolute" : "")}
                        id={"pointsWithContent-img-rightSide"+props.data.href}
                        src={props.data.data.srcImgRight}
                        alt={"img-with-message-rightSide"}
                        style={{
                            maxHeight: props.data.data.maxHeightImgRight ?? "",
                            visibility: "hidden"
                        }}
                        onLoad={()=>{showImgRight()}}
                    />
                    :
                    ""
                }
            </div>
            
        </div>
      </div>
      </div>
    </section>
  );
};
