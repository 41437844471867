// 29/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useLayoutEffect } from "react";
import { Header } from "./header";
import data from "../data/data.json";
// import { pagesContact } from "../data/data.json";
import { FooterGeogart } from "./footerGeogart";
export const ContactMobile = (props) => {
    const paginas=[];
    const dataHeader = data.pages.filter((e) => e.pageName === "Header")[0];
    dataHeader.data.backgroundcolor = "#0f1c2d"
    for(let i=0; i<data.pagesContact.length; i++){
        if(data.pagesContact[i].pageName==="FooterGeogart"){
            paginas.push({pagina: <FooterGeogart data={data.pagesContact[i]} key={Math.random()}/>, order: data.pagesContact[i].order});
        }
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    //console.log(paginas);
    return (
        <div className="geoInteligencia contact-mobile-container-desktop">
            <Header data={dataHeader} />
            <div>
                {paginas
                .sort((a, b) => a.order - b.order)
                .map((e) => e.pagina)}
            </div>
        </div>
    );
};
