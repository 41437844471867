import React, { useEffect, useState,useCallback } from "react";
import { Link/*, useNavigate*/, useLocation } from "react-router-dom";
import InnerImageZoom from "react-inner-image-zoom";
import Swal from "sweetalert2";
import useEmblaCarousel from "embla-carousel-react";

//Components
import { Header } from "./header";
import { Footer } from "./footer";

//Hooks
import { useCart } from "../hook/useCart";

//Data
import products from "../data/products.json";
import data from "../data/data.json";
//CSS
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

export const ProductDetails = () => {
  const location = useLocation();
  //const navigate = useNavigate();
  const { cart, setCart } = useCart();
  const idParams = location.search.slice(
    location.search.lastIndexOf("=") + 1,
    location.search.length
  );
  const [product, setProduct] = useState();
  const currency = function (number) {
    return new Intl.NumberFormat("de-DE").format(number);
  };

  useEffect(() => {
    for(let i=0;i<products.length;i++){
      if(products[i].id===idParams){
        setProduct(products[i]);
      }
    }
    /*products.filter((product) => {
      if (product.id === idParams) {
        setProduct(product);
      }
    });*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e, product) => {
    e.preventDefault();
    const productoSeleccionado = {
      productId: product.id,
      productName: product.title,
      productImg: product.image[0],
      productPrice: product.productPriceNew,
      productQuantity: 1,
    };

    const existe = cart?.some(
      (product) => product.productId === productoSeleccionado.productId
    );

    if (existe) {
      const product = cart?.map((product) => {
        if (product.productId === productoSeleccionado.productId) {
          product.productQuantity += 1;
          return product;
        } else {
          return product;
        }
      });

      setCart([...product]);
      localStorage.setItem("cart", JSON.stringify(product ?? ""));
    } else {
      setCart([...(cart ?? []), productoSeleccionado]);
      localStorage.setItem("cart", JSON.stringify(cart ?? ""));
    }
    Swal.fire(
      "Producto Agregado",
      `${productoSeleccionado.productName} ha sido agregado al carrito exitozamente `,
      "success"
    );
  };
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSubmit]);

  //Embla Carousel
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  const DotButton = ({ selected, onClick }) => (
    <button
      className={`embla__dot ${selected ? "is-selected" : ""}`}
      type="button"
      onClick={onClick}
    />
  );

  const PrevButton = ({ enabled, onClick }) => (
    <button
      className="embla__button embla__button--prev"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
        <path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
      </svg>
    </button>
  );

  const NextButton = ({ enabled, onClick }) => (
    <button
      className="embla__button embla__button--next"
      onClick={onClick}
      disabled={!enabled}
    >
      <svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
        <path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
      </svg>
    </button>
  );

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  

  return (
    <React.Fragment>
      <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
      <div style={{ background: "#eeeeee", padding: "10px" }}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="titleContainer">
                <Link className="dt-volver-link" to="/#products">
                  {" "}
                  <i class="bx bx-arrow-back"></i> Volver a los Productos
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card" style={{ border: "none" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-xl-6">
                      <div className="embla">
                        <div className="embla__viewport" ref={viewportRef}>
                          <div className="embla__container">
                            {product?.image.map((image, index) => (
                              <div className="embla__slide" key={index}>
                                <div className="embla__slide__inner__DETAILS__PRODUCT">
                                  <InnerImageZoom
                                    src={image}
                                    alt={product?.title}
                                    key={index}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <PrevButton
                          onClick={scrollPrev}
                          enabled={prevBtnEnabled}
                        />
                        <NextButton
                          onClick={scrollNext}
                          enabled={nextBtnEnabled}
                        />
                      </div>
                      <div className="embla__dots">
                        {scrollSnaps.map((_, index) => (
                          <DotButton
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => scrollTo(index)}
                          />
                        ))}
                      </div>
                      {/* {product?.image.map((image, key) => (
                        <InnerImageZoom
                          src={image}
                          alt={product?.title}
                          key={key}
                        />
                      ))} */}
                    </div>
                    <div className="col-xl-6">
                      <div className="mt-4 mt-xl-3">
                        <h3>{product?.title}</h3>
                        <h5>
                          {product?.productPriceNew > 0 &&
                          product?.productPriceNew < product?.productPrice ? (
                            <div>
                              <span className="text-muted me-2">
                                <del>${currency(product?.productPrice)}</del>
                              </span>
                              <b class="price">
                                ${currency(product?.productPriceNew)}
                              </b>
                            </div>
                          ) : (
                            <div>
                              <b class="price">
                                ${currency(product?.productPrice)}
                              </b>
                            </div>
                          )}
                        </h5>
                        <p
                          style={{ fontSize: "14px" }}
                          dangerouslySetInnerHTML={{
                            __html: product?.productDescription,
                          }}
                        />
                        <button
                          class="portfolio-add-button"
                          style={{
                            width: "100%",
                            border: "none",
                            height: "30px",
                          }}
                          onClick={(e) => {
                            handleSubmit(e, product);
                            console.log(product);
                          }}
                        >
                          <i className="bx bx-cart"></i> {"Agregar al Carrito"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer data={data.pages.filter((e) => e.pageName === "Footer")[0]} />
    </React.Fragment>
  );
};
