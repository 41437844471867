import React, { useState } from "react";
import { Link, useNavigate/*, useLocation*/ } from "react-router-dom";
import { message } from "antd";
import { Header } from "./header";
import data from "../data/data.json";
import { Loading } from "./loading";

import puntoApi from "../custom/axios/js/axios";
import useAuth from "../hook/useAuth";
import { useEffect } from "react";

export const Login = (props) => {
  const dataLogin = data.pages.filter((e) => e.pageName === "Login")[0].data;

  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auxUserEmail] = useState({email: ""});
  const [auxUserPassword] = useState({password: ""});
  //const [errMsg, setErrMsg] = useState("");
  const adId = localStorage.getItem("adId");
  //const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
    auxUserEmail.email=event.target.value;
  };
  const onChangePassword = (event) => {
    setPassword(event.target.value);
    auxUserPassword.password=event.target.value;
  };
  const serverEmail = (email) => {
    const lastIndex = email.lastIndexOf("@");
    return email.slice(lastIndex + 1, email.length);
  };
  const success = () => {
    message.success("Has ingresado correctamente");
  };
  /*const error = () => {
    message.error("Ha ocurrido un error, verifica tu email y/o contraseña");
  };*/
  const handleLogin = async (e) => {
    if(e){
      e.preventDefault();
    }
    let emailSendBack = auxUserEmail.email;
    let passwordSendBack = auxUserPassword.password;
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              emailSendBack + ":GEODART:EMAIL:::" + serverEmail(emailSendBack) + ":" + passwordSendBack
            ).toString("base64"),
        },
      };
      // console.log(config);
      const response = await puntoApi.post(
        "/auth/v1/sign-in-provider",
        config,
        config
      );
      const data = response?.data?.data;
      // console.log(response);
      // console.log(data);
      if (response.data.isSuccessful === true) {
        success();
        const accessToken = data.map((data) => data.tokenId);
        const userName = data.map((data) => data.displayName);

        setEmail("");
        setPassword("");
        setIsLoading(false);
        const token = {
          headers: {
            orgId: "GEODART",
            Authorization: "Bearer " + accessToken,
            accessToken: accessToken,
          },
        };
        const dataInfo = {
          userName: userName[0],
          token: token,
        };
        setAuth(dataInfo);
        localStorage.setItem("auth", JSON.stringify(dataInfo));
        localStorage.setItem("lastDateDataBack",new Date());
        if(sessionStorage.pathAfterLogin){
          let auxPath=sessionStorage.pathAfterLogin;
          sessionStorage.removeItem("pathAfterLogin");
          navigate(auxPath);
        }else{
          navigate("/statePostulations");
        }
        if (adId != null) {
          localStorage.removeItem("adId");
        }
      } 
    } catch (err) {
      setIsLoading(false);
  
      // Manejo del error 401 Unauthorized
      if (err.response && err.response.status === 401) {
        message.error("La cuenta aún no ha sido aprobada.");
      } else {
        message.error("Error al iniciar sesión. Revise el email y la contraseña.");
      }
    }
  };
 
  useEffect(()=>{
    let inputPassword = document.getElementById("passwordFormLogin");
    if(inputPassword){

      inputPassword.addEventListener("keypress", (event) => {
        if(event.key==="Enter"){
          event.preventDefault();
          handleLogin();
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div
      className="register-container"
      style={{
        backgroundImage: "url('"+dataLogin.backgroundImg+"')",
      }}
    >
    <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
      <div className="register-content">
        {isLoading ?
          <Loading />
          :
          ""
        }
        <div className="register-form">
          <div className="fadeIn register-img-logo">
            <img
              src={dataLogin.img}
              className="img-fluid"
              alt="Geogart-Icon"
              style={{
                maxHeight: dataLogin.maxHeight ?? "60px"
              }}
            />
          </div>
          <h2 className="register-title">{dataLogin.titlePage}</h2>
          <form onSubmit={(e) => handleLogin(e)} className="register-form-object">
            <input
              type="text"
              id="emailLogin"
              className="register-input-all-width register-input-color-placeholder"
              name="email"
              placeholder={dataLogin.placeHolderEmail}
              value={email}
              onChange={onChangeEmail}
              required
            />
            <input
              type="password"
              id="passwordFormLogin"
              className="register-input-all-width register-input-color-placeholder register-input-password"
              name="password"
              placeholder={dataLogin.placeHolderPassword}
              value={password}
              onChange={onChangePassword}
              required
            />
            <Link
              to={dataLogin.hrefChangePassword ? dataLogin.hrefChangePassword : "/forgotPassword"}
            >
              <p className="login-forgot-password-text">{dataLogin.changePasswordText}</p>
            </Link>
            <div className="register-container-buttons">
              <button className="register-button register-button-have-account login-button-register" value="Log In" onClick={()=>{navigate("/register")}}>
                {dataLogin.createAnAccount}
              </button>
              <button className="register-button register-button-register-account" value="Log In">
                {dataLogin.buttonLogin}
              </button>
            </div>
          </form>
        </div>
        <p className="register-text-down">{dataLogin.textDown}</p>
      </div>
    </div>
  );
};
