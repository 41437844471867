import React, {  useEffect, useState } from "react";

import { /*Link,*/ useNavigate,useLocation } from "react-router-dom";
import Swal from "sweetalert2"
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
import puntoApi from '../custom/axios/js/axios';
import { Header } from "./header";
import { Loading } from "./loading";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import data from "../data/data.json";

import useAuth from "../hook/useAuth";


//Variables Globales


export const PublicationDetails = () => {
  const location = useLocation();
  const dataPage = data.pages.filter(e=> e.pageName === "PublicationDetails")[0].data;
  const adIdParams = location.search.slice(location.search.lastIndexOf('=')+1, location.search.length);
  const [isLoading, setIsLoading] = useState(false);
    const { setAuth } = useAuth();
    //const authLocal = auth !== undefined ? auth : JSON.parse(localStorage.getItem("auth"));
    const authLocalStorage = localStorage.getItem("auth");
    // console.log(authLocalStorage);
    const navigate = useNavigate();
    const [publications, setPublications] = useState();
    const [checked, setChecked] = useState(false);
    //datos postular
    const [m2, setM2] = useState("");
    const [value, setValue] = useState("");
    const [address, setAddress] = useState("");
    const [description, setDescription] = useState("");
    const [postId, setPostId] = useState("");
    useEffect(()=>{
      let authLocalStorage = localStorage.getItem("auth");
      if(!authLocalStorage){
          navigate("/");
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(() => {
      async function fetchApiDetail() {
        // console.log(adIdParams);
        let urlAux="";
        let authLocalAux = JSON.parse(localStorage.getItem("auth"));
        if(!authLocalAux){
          return; 
        }
        const token = authLocalAux?.token;
        // console.log(authLocalAux);
        // console.log(token);
        if(token){
          urlAux="/camp/broker-logged/v1?adId="+adIdParams.toString();
        }else{
          urlAux="/camp/broker/v1?adId="+adIdParams.toString();
        }
        //token.headers.authorization = "a11a2a22a2";
        // console.log(token);
        try{  
          const response = await puntoApi.get(urlAux,token);
          // console.log(response);
          if(response.status!==200){
            localStorage.removeItem("auth");
            sessionStorage.pathAfterLogin=location.pathname+location.search;
            setAuth(null);
            navigate("/login");
          }
          if(response.data.data[0].postId){
            setPostId(response.data.data[0].adId);
          }
          setPublications(
            response?.data.data.filter(
              (publication) => publication.adId === (adIdParams)
            )
          );
        }catch(err){
          localStorage.removeItem("auth");
          sessionStorage.pathAfterLogin=location.pathname+location.search;
          setAuth(null);
          navigate("/login");
        }
      }
        
    fetchApiDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [puntoApi]);
  
  //formato de moneda
  /*COMENTADO SRODRIGUEZ
function currency(value) {
    if (isNaN(value)) {
      return '$ 0';
    } else {
      return `$ ${Math.round(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }
}
/*


//funcion para mantener estado del id de la publicacion
/* COMENTADO SRODRIGUEZ
const idPublication = () => {
  publications?.map((data) =>  localStorage.setItem('adId',data.adId));
  
}
*/



  const numberFormat = function(number){
    return new Intl.NumberFormat('de-DE').format(number) 
  };

  // acciones de click
  const handleClickPostular = (e, checked) => {
    e.preventDefault();
    if (localStorage.getItem("auth")) {
      setChecked(checked);
    } else {
      navigate("/login",{state:`${location.pathname}${location.search}`});
      localStorage.setItem('adId',adIdParams)
    }
  };
  const handleEnviarPostulacion = async (e) => {
    e.preventDefault();
    /*const adIdpublication = publications.map((data) => data.adId); SRODRIGUEZ COMENTADO*/
    const body = {
      adId: adIdParams,
      postM2: parseInt(m2),
      postValue: parseInt(value),
      postAddress: address,
      postDescription: description,
    };
    setIsLoading(true);
    let authLocalStorage = localStorage.getItem("auth");
    if(!authLocalStorage){
      return;
    }
    const token = JSON.parse(authLocalStorage).token;
    // console.log("token", token);
    //console.log(body);
    /*const response = */await puntoApi.post("campBroker/v1", body, token).then( (response) => {
      setIsLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Postulacion Correcta',
          showConfirmButton: false,
          timer: 1500
        });
        navigate('/postulations')
  
    }

    ).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Error al postular',
        text:'Revisar los datos de tu postulacion o que no hayas postulado anteriormente',
        showConfirmButton: true,
        timer: 4000
      });
      setIsLoading(false);
    });
    
    // if(response?.data?.isSuccessful){
    //   Swal.fire({
    //     icon: 'success',
    //     title: 'Postulacion Correcta',
    //     showConfirmButton: false,
    //     timer: 1500
    //   });
    //   navigate('/postulations')
    // }else{
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error al postular',
    //     message:'Revisar los datos de tu postulacion',
    //     showConfirmButton: true,
    //     timer: 1500
    //   })
    // }
    // console.log(response.data);
  };

  //onChange form postular
  const onChangeAddress = (event) => {
    setAddress(event.target.value);
  };
  const onChangeM2 = (event) => {
    setM2(event.target.value);
  };
  const onChangeValue = (event) => {
    
    setValue(event.target.value);
  };
  const onChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const setMayusText = (text) => {
    return text.toUpperCase();
  }

  return (
    <section
      style={{backgroundColor: dataPage.backgroundColor ?? ""}}
      className="publication-detail"
    >
      <Header data={data.pages.filter(e=> e.pageName === "Header")[0]} />
      <div
        className="dt-publication-color"
      >
        <div className="publication-detail-container-summary">
          {publications?.map((publication, index) => (
            <div key={publication.adId || index}>
              <div className="publication-detail-container-summary-operation-first-part">
                <div className="publication-detail-container-summary-operation-first-part-img">
                  <img
                    alt="publication-img"
                    src={publication.operationTypeName === "Compra" ? dataPage.iconBuy : dataPage.iconArriend}
                    className="img-fluid publication-detail-container-summary-operation-first-part-img-image"
                    style={{
                      minHeight: dataPage.minHeightIcon ?? ""
                    }}
                  />
                </div>
                <div className="publication-detail-container-summary-operation-first-part-info">
                  <h3 className="publication-detail-container-summary-operation">{setMayusText(publication.operationTypeName)}</h3>
                  <h1
                    className="publication-detail-container-summary-name"
                    dangerouslySetInnerHTML={{
                      __html: publication.adName ?? ""
                    }}
                  />
                  <h4 className="publication-detail-container-summary-description  publication-detail-container-summary-description-desktop">
                    {publication.countryName}, {publication.cityName} |{" "}
                    {numberFormat (publication.adM2From)} m² a { numberFormat(publication.adM2To) } m²  | {publication.adZone}
                  </h4>
                  <div className="publication-detail-container-summary-description-mobile">
                    <p className="publication-detail-container-summary-description-text">{publication.countryName}, {publication.cityName}</p>
                    <p className="publication-detail-container-summary-description-text">{numberFormat (publication.adM2From)} m² a { numberFormat(publication.adM2To) } m²</p>
                    <p className="publication-detail-container-summary-description-text">{publication.adZone}</p>
                  </div>
                </div>
              </div>
              {
                authLocalStorage ?
                <div className="publication-properties-details-container-button publication-properties-details-container-button-desktop">
                  <button
                    className = "publication-properties-details-button"
                    onClick={(e)=>{
                      if (postId) {
                        navigate("/postulation/details?postId="+postId);
                      }else{
                        handleClickPostular(e, true);
                      }
                    }}
                  >
                    {postId ? dataPage.buttonCheckPostulate : dataPage.buttonPostulate}
                  </button>
                </div>
                :
                <div className="publication-properties-details-container-button publication-properties-details-container-button-desktop">
                  <button
                    className = "publication-properties-details-button"
                    onClick={(e)=>{
                      navigate("/login");
                    }}
                  >
                    {dataPage.buttonLoginText ?? "Inicia sesión para postular a este aviso"}
                  </button>
                </div>
              }
            </div>
          ))}
        </div>
        {
          authLocalStorage ?
          <div className="publication-properties-details-container-button-mobile">
            <button
              className = "publication-properties-details-button"
              onClick={(e)=>{
                if (postId) {
                  navigate("/postulation/details?postId="+postId);
                }else{
                  handleClickPostular(e, true);
                }
              }}
            >
              {postId ? dataPage.buttonCheckPostulate : dataPage.buttonPostulate}
            </button>
          </div>
          :
          <div className="publication-properties-details-container-button-mobile">
            <button
              className = "publication-properties-details-button"
              onClick={(e)=>{
                navigate("/login");
              }}
            >
              {dataPage.buttonLoginText ?? "Inicia sesión para postular a este aviso"}
            </button>
          </div>
        }
        <div className="publication-detail-container-postulation">
          {publications?.map((publication, index) => (
            <div className="dt-publication-descrip" key={publication.adId || index}>
              <ReactQuill
                value={JSON.parse(publication.adDescriptionWithFormat)}
                readOnly={true}
                theme="bubble" // `bubble` es un tema minimalista para solo lectura
              />
            </div>
          ))}
          {checked ? (
            <>
            {isLoading && <Loading />}
            <div className="dt-publication-postu">
              <h2
                className="dt-publication-form-title"
                dangerouslySetInnerHTML={{
                  __html: dataPage.titlePostulation ?? ""
                }}
              />
              <form
                onSubmit={(e) => handleEnviarPostulacion(e)}
                className="dt-publication-postu-content-form-item"
              >
                <div className="row dt-publication-postu-content-form-item-first">
                  <div className="mb-3">
                    <label className="dt-publication-postu-form-text dt-publication-postu-form-label" htmlFor="address">{dataPage.labelAddress}</label>
                    <input
                      type="text"
                      className="form-control dt-publication-postu-form-text dt-publication-postu-form-input"
                      id="address"
                      placeholder={dataPage.labelAddress}
                      value={address}
                      onChange={onChangeAddress}
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3 col-lg-6">
                    <label className="dt-publication-postu-form-text dt-publication-postu-form-label" htmlFor="M2">{dataPage.labelM2}</label>
                    <input
                      type="number"
                      className="form-control dt-publication-postu-form-text dt-publication-postu-form-input"
                      id="m2"
                      placeholder={dataPage.labelM2}
                      value={m2}
                      onChange={onChangeM2}
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3 col-lg-6">
                    <label className="dt-publication-postu-form-text dt-publication-postu-form-label" htmlFor="lastName">{dataPage.labelValue}</label>
                    <input
                      type="number"
                      className="form-control dt-publication-postu-form-text dt-publication-postu-form-input"
                      id="value"
                      placeholder={dataPage.labelValue}
                      value={ value}
                      onChange={onChangeValue}
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="dt-publication-postu-form-text dt-publication-postu-form-label" htmlFor="address2"> {dataPage.labelDescription} </label>
                  <textarea
                    
                    rows="8"
                    type="text"
                    className="form-control dt-publication-postu-form-text dt-publication-postu-form-input dt-publication-postu-form-textarea"
                    id="address2"
                    placeholder={dataPage.labelDescription}
                    value={description}
                    onChange={onChangeDescription}
                  />
                </div>
                <div className="publication-properties-details-button-container">
                  <button
                    className = "publication-properties-details-button publication-properties-details-button-send" type="submit"
                    style={{
                      marginBottom: "15px"
                    }}
                  >
                    <i className="mdi mdi-cart-arrow-right me-1" />{" "}
                    {dataPage.buttonSendPostulation}{" "}
                  </button>
                </div>
              </form>
            </div>
            </>
          ) : null}
        </div>
      </div>
    </section>
  );
};


