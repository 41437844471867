//import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
export const TextWithImage = (props) => {
    //const navigate = useNavigate();
    //console.log(props);
    const detectScrollTextWithImage = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemTitle=document.getElementById("text-with-image-title " + props.data.order.toString());
        let elemBtn=document.getElementById("text-with-image-button " + props.data.order.toString());
        if(!elem.classList.contains("animation_showed")){
            if(isVisible){
                if(elemTitle!==null){
                    elemTitle.classList.remove("animate__fadeOut");
                    elemTitle.classList.add("animate__fadeIn");
                }
                if(elemBtn!==null){
                    elemBtn.classList.remove("animate__fadeOutLeft");
                    elemBtn.classList.add("animate__fadeInLeft");
                }
                elem.classList.add("animation_showed");
            }else{
                if(elemTitle!==null){
                    elemTitle.classList.remove("animate__fadeIn");
                    elemTitle.classList.add("animate__fadeOut");
                }
                if(elemBtn!==null){
                    elemBtn.classList.remove("animate__fadeInLeft");
                    elemBtn.classList.add("animate__fadeOutLeft");
                }
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollTextWithImage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollTextWithImage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ''} className="textWithImgAnimated">
            <div
                className="text-with-image-container-component"
                style={{
                    alignItems: props.data.data.positionTitleAndParagraph ?? "center"
                }}
            >
                <div className="text-with-image-container">
                    {
                        props.data.data.imgLeft ?
                        <div></div>
                        :
                        <div className={"col-lg-"+ (props.data.data.relationSizeTextPart ?? "6") +" text-with-image-text"}
                            style={{
                                paddingLeft: props.data.data.marginRightText ?? "",
                                display: props.data.data.positionTextTileAndParagraph === "left" ? "blok" : "",
                            }}
                        >
                        <h3
                            id={"text-with-image-title " + props.data.order.toString()}
                            className="text-with-image-title animate__animated"
                            style={{
                                fontSize: props.data.data.sizeFontTitle ?? "",
                                color: props.data.data.colorTitle ?? "",
                                textAlign: props.data.data.positionTextTileAndParagraph ?? "",
                            }}
                        >
                            {props.data.data.title}
                        </h3>
                        <br/>
                        <p className="text-with-image-paragraph"
                            style={{color:props.data.data.paragraphColor ? props.data.data.paragraphColor : "",
                                fontSize: props.data.data.sizeFontParagraph ? props.data.data.sizeFontParagraph : "",
                                textAlign: props.data.data.positionTextTileAndParagraph ?? "",
                            }}
                        >
                            {props.data.data.paragraph}
                        </p>
                        {
                            props.data.data.textButton ?
                            <div>
                                <button
                                    id={"text-with-image-button " + props.data.order.toString()}
                                    className="our-offer-time-button text-with text-with-image-button animate__animated"
                                    style={{
                                        color: props.data.data.colorTextButton ? props.data.data.colorTextButton : "",
                                        backgroundColor: props.data.data.backgroundcolorButton ?? "",
                                        fontSize: props.data.data.fontSizeTextButton ?? ""
                                    }}
                                >
                                    <a href={props.data.data.hrefButton}
                                        style={{
                                            color: props.data.data.colorTextButton ? props.data.data.colorTextButton : "",
                                        }}  
                                    >
                                        {props.data.data.textButton}
                                    </a>
                                </button>
                            </div>
                            :
                            ""
                        }
                        </div>
                    }
                    {
                        props.data.data.hasTextDown ?
                        <div className={"col-lg-"+ (props.data.data.relationSizeImgPart ?? "6") +" text-with-image-img-with-text-down"}>
                            {props.data.data.img !== "" ?
                            <img 
                                src={props.data.data.img}
                                className="img-fluid"
                                alt={props.data.data.title}
                                style={{
                                    minHeight: props.data.data.minHeightImg ?? "",
                                    minWidth: props.data.data.minWidthImg ?? ""
                                }}
                            /> :
                            props.data.data.video !== "" ?
                            <video
                                /* src={props.data.data.src} */
                                alt={props.data.data.title}
                                id="videoWelcome"
                                autoPlay={true}
                                loop={true}
                                muted
                                className="img-fluid"
                                style={{
                                objectFit: "cover",
                                filter: props.data.data.filter ?? "grayscale(50%)",
                                }}
                            >
                                {" "}
                                <source src={props.data.data.video} type="video/webm" />{" "}
                            </video>
                            :
                            ""
                            }
                            <div className="text-with-image-img-with-text-down-text"
                                style={{backgroundColor: props.data.data.backgroundColor ? props.data.data.backgroundColor : ""}}
                            >
                                <p
                                    style={{color:props.data.data.textDownColor ? props.data.data.textDownColor : "",
                                        fontSize: props.data.data.sizeFontTextDown ? props.data.data.sizeFontTextDown : ""
                                    }}
                                >
                                    {props.data.data.textDown}
                                </p>
                            </div>
                        </div>
                        :
                        <div className={"col-lg-"+ (props.data.data.relationSizeImgPart ?? "6") +" text-with-image-img"}
                            style={{background: props.data.data.backgroundPosition === "right" ?
                                "linear-gradient(270deg, "+ props.data.data.backgroundColor +" 50%, transparent 0%)" :
                                props.data.data.backgroundPosition === "left" ?
                                "linear-gradient(90deg, "+ props.data.data.backgroundColor +" 50%, transparent 0%)" :
                                props.data.data.backgroundPosition === "up" ?
                                "linear-gradient(180deg, "+ props.data.data.backgroundColor +" 50%, transparent 0%)" :
                                props.data.data.backgroundPosition === "down" ?
                                "linear-gradient(0deg, "+ props.data.data.backgroundColor +" 50%, transparent 0%)" :
                                ""
                            }}
                        >
                            {props.data.data.img !== "" ?
                            <img 
                                src={props.data.data.img}
                                className="img-fluid"
                                alt={props.data.data.title}
                                style={{
                                    
                                    minHeight: props.data.data.minHeightImg ?? "",
                                    minWidth: props.data.data.minWidthImg ?? ""
                                }}
                            />: 
                            props.data.data.video !== "" ?
                            <video
                                /* src={props.data.data.src} */
                                alt={props.data.data.title}
                                id="videoWelcome"
                                autoPlay={true}
                                loop={true}
                                muted
                                className="img-fluid"
                                style={{
                                    minHeight: props.data.data.minHeightImg ?? "",
                                    minWidth: props.data.data.minWidthImg ?? "",
                                    
                                    
                                }}
                            >
                                {" "}
                                <source src={props.data.data.video} type="video/webm" />{" "}
                            </video>
                            :
                            ""
                            }
                        </div>    
                    }
                    {
                        props.data.data.imgLeft ?
                        <div className={"col-lg-"+ (props.data.data.relationSizeTextPart ?? "6") +" text-with-image-text"}
                        style={{
                            paddingLeft: props.data.data.marginRightText ?? "",
                            display: props.data.data.positionTextTileAndParagraph === "left" ? "block" : "",
                        }}>
                        <h3
                            id={"text-with-image-title " + props.data.order.toString()}
                            className="text-with-image-title animate__animated"
                            style={{
                                fontSize: props.data.data.sizeFontTitle ?? "",
                                color: props.data.data.colorTitle ?? "",
                                textAlign: props.data.data.positionTextTileAndParagraph ?? "",
                            }}
                        >
                            {props.data.data.title}
                        </h3>
                        <br/>
                        <p className="text-with-image-paragraph"
                            style={{color:props.data.data.paragraphColor ? props.data.data.paragraphColor : "",
                                fontSize: props.data.data.sizeFontParagraph ? props.data.data.sizeFontParagraph : "",
                                textAlign: props.data.data.positionTextTileAndParagraph ?? "",
                            }}
                        >
                            {props.data.data.paragraph}
                        </p>
                        {
                            props.data.data.textButton ?
                            <div>
                                <button
                                    id={"text-with-image-button " + props.data.order.toString()}
                                    className="our-offer-time-button text-with text-with-image-button animate__animated"
                                    style={{
                                        color: props.data.data.colorTextButton ? props.data.data.colorTextButton : "",
                                        backgroundColor: props.data.data.backgroundcolorButton ?? ""
                                    }}
                                    
                                >
                                    <a href={props.data.data.hrefButton}>
                                    {props.data.data.textButton}
                                    </a>
                                </button>
                            </div>
                            :
                            ""
                        }
                        </div>
                        :
                        <div></div>
                    }
                </div>                    
            </div>
        </section>
    )
}