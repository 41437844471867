// 05/05/2023 v0.0.3 - Enmanuelle
import React from "react";
export const PointsWorld = (props) => {
  return (
    <section
      id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
      className="pointsWorld"
    >
        <div className="pointsWorld-cointainer-component">
            <div className="imgWithMessage-container pointsWorld-container">
                {props.data.data.src === "" ? (
                <div
                    className="Aux"
                    alt={props.data.data.title}
                    style={{
                        background:
                            "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))",
                        maxHeight: props.data.data.maxHeight ?? "300px",
                        minHeight: props.data.data.minHeight ?? "800px"
                    }}
                />
                ) : (props.data.data.showVideo ?? false) ? (
                <video
                    /* src={props.data.data.src} */
                    alt={props.data.data.title}
                    id="videoWelcome"
                    autoPlay={true}
                    loop={true}
                    muted
                    style={{
                    width: "100%",
                    height: "100%",
                    minHeight: props.data.data.maxHeight ?? "500px",
                    maxHeight: props.data.data.maxHeight ?? "300px",
                    objectFit: "cover",
                    filter: props.data.data.filter ?? "grayscale(50%)",
                    }}
                >
                    {" "}
                    <source src={props.data.data.src} type="video/mp4" />{" "}
                </video>
                ) : (
                <img
                    src={props.data.data.src}
                    alt={props.data.data.title}
                    style={{
                    width: "100%",
                    height: "100%",
                    minHeight: props.data.data.maxHeight ?? "500px",
                    maxHeight: props.data.data.maxHeight ?? "500px",
                    objectFit: "",
                    }}
                    className="imgWithBigText-img"
                />
                )}

                <div
                className="pointsWorld-centered"
                style={{
                    paddingTop: "0px",
                }}
                >
                    <h2
                        className="pointsWorld-title"
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.title
                            ? props.data.data.title
                            : "",
                        }}
                        style={{
                            fontSize: props.data.data.fontSizeTitle ?? "",
                            color: props.data.data.colorTitle ?? ""
                        }}
                    />
                    <div className="pointsWorld-container-icons">
                        <div className="pointsWorld-container-two-icons-1">
                            <div className="pointsWorld-container-icon1">
                                <h4
                                    className="pointsWorld-item-title"
                                    dangerouslySetInnerHTML={{
                                        __html: props.data.data.items[0].title
                                        ? props.data.data.items[0].title
                                        : "",
                                    }}
                                    style={{
                                        fontSize: props.data.data.fontSizeTitleItems ?? "",
                                    }}
                                />
                                <p
                                    className="pointsWorld-item-text"
                                >
                                    {props.data.data.items[0].text}
                                </p>
                                <img
                                    alt="pointsWorld-container-icon"
                                    src={props.data.data.items[0].src}
                                    className="img-fluid"
                                    style={{
                                        maxHeight: "150px"
                                    }}
                                />
                            </div>
                            <div className="pointsWorld-container-icon2">
                                <img
                                    alt="pointsWorld-container-icon"
                                    src={props.data.data.items[1].src}
                                    className="img-fluid"
                                    style={{
                                        maxHeight: "150px"
                                    }}
                                />
                                <h4
                                    className="pointsWorld-item-title"
                                    dangerouslySetInnerHTML={{
                                        __html: props.data.data.items[1].title
                                        ? props.data.data.items[1].title
                                        : "",
                                    }}
                                    style={{
                                        fontSize: props.data.data.fontSizeTitleItems ?? "",
                                    }}
                                />
                                <p
                                    className="pointsWorld-item-text"
                                >
                                    {props.data.data.items[1].text}
                                </p>
                            </div>
                        </div>
                        <div className="pointsWorld-container-two-icons-2">
                            <div className="pointsWorld-container-icon3">
                                <h4
                                    className="pointsWorld-item-title"
                                    dangerouslySetInnerHTML={{
                                        __html: props.data.data.items[2].title
                                        ? props.data.data.items[2].title
                                        : "",
                                    }}
                                    style={{
                                        fontSize: props.data.data.fontSizeTitleItems ?? "",
                                    }}
                                />
                                <p
                                    className="pointsWorld-item-text"
                                >
                                    {props.data.data.items[2].text}
                                </p>
                                <img
                                    alt="pointsWorld-container-icon"
                                    src={props.data.data.items[2].src}
                                    className="img-fluid"
                                    style={{
                                        maxHeight: "150px"
                                    }}
                                />
                            </div>
                            <div className="pointsWorld-container-icon4">
                                <img
                                    alt="pointsWorld-container-icon"
                                    src={props.data.data.items[3].src}
                                    className="img-fluid"
                                    style={{
                                        maxHeight: "150px"
                                    }}
                                />
                                <h4
                                    className="pointsWorld-item-title"
                                    dangerouslySetInnerHTML={{
                                        __html: props.data.data.items[3].title
                                        ? props.data.data.items[3].title
                                        : "",
                                    }}
                                    style={{
                                        fontSize: props.data.data.fontSizeTitleItems ?? "",
                                    }}
                                />
                                <p
                                    className="pointsWorld-item-text"
                                >
                                    {props.data.data.items[3].text}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
    </section>
  );
};
