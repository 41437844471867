// 29/05/2023 v0.0.1 - Enmanuelle
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./header";
import data from "../data/data.json";
// import useAuth from "../hook/useAuth";
export const StatePostulations = (props) => {
    const dataHeader = data.pages.filter((page) => page.pageName==="Header")[0];
    const navigate = useNavigate();
    // const { auth/*, setAuth*/ } = useAuth();
    useEffect(()=>{
        // console.log(auth);
        // let authLocalStorage = localStorage.auth;
        // console.log(localStorage.auth);
        // console.log(authLocalStorage);
        setTimeout(()=>{
            if(!localStorage.auth){
                console.log("deslogeado");
                navigate("/");
            }else{
                console.log("logeado");
            }
        },0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <div
            id={props.data ? props.data.href.substring(1, props.data.href.len) : ''}
            className="statePostulations"
            style={{
                backgroundImage: "url('" + (props.data.data.backgroundImg ?? "") + "')"
            }}
        >
            <Header data={dataHeader} />
            <div className="statePostulations-container">
                <img
                    src={props.data.data.srcImgLogo}
                    alt="logo-geodart"
                    className="img-fluid state-postularions-logo"
                    style={{
                        maxHeight: props.data.data.maxHeightLogo ?? "",
                        marginBottom: "30px"
                    }}
                />
                <h1
                    className="statePostulations-title"
                >
                    {props.data.data.title}
                </h1>
                <p
                    className="statePostulations-paragraph"
                    dangerouslySetInnerHTML={{
                        __html: props.data.data.paragraph ?? ""
                    }}
                />
                <div className="statePostulations-options">
                    <div className="statePostulations-options-option1">
                        <img
                            className="img-fluid statePostulations-options-img1"
                            style={{
                                maxHeight: props.data.data.maxHeightIcon ?? ""
                            }}
                            alt={props.data.data.option1.buttonText}
                            src={props.data.data.option1.logo}
                        />
                        <button
                            className="statePostulations-options-button"
                            onClick={()=>{navigate(props.data.data.option1.href)}}
                        >
                            {props.data.data.option1.buttonText}
                        </button>
                    </div>
                    <div className="statePostulations-options-option2">
                        <img
                            className="img-fluid statePostulations-options-img2"
                            style={{
                                maxHeight: props.data.data.maxHeightIcon ?? ""
                            }}
                            alt={props.data.data.option2.buttonText}
                            src={props.data.data.option2.logo}
                        />
                        <button
                            className="statePostulations-options-button"
                            onClick={()=>{navigate(props.data.data.option2.href)}}
                        >
                            {props.data.data.option2.buttonText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
