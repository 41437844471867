// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useLayoutEffect } from "react";
import { Header } from "./header";
import data from "../data/data.json";
// import {pagesGeoInteligencia} from "../data/data.json";
import { PointsWithContent } from "./pointsWithContent";
import { IconsWithDetail } from "./iconsWithDetail";
import { TwoSectionDetail } from "./twoSectionDetail";
import { ImgWithBigText } from "./imgWithBigText";
import { PointsWorld } from "./pointsWorld";
import { FooterGeogart } from "./footerGeogart";
export const GeoInteligencia = (props) => {
    const paginas=[];
    const dataHeader = data.pages.filter((e) => e.pageName === "Header")[0];
    dataHeader.data.backgroundcolor = "#0f1c2d"
    //console.log(pagesGeoInteligencia);
    for(let i=0; i<data.pagesGeoInteligencia.length; i++){
        //console.log(pagesGeoInteligencia[i]);
        if(data.pagesGeoInteligencia[i].pageName==="PointsWithContent"){
            paginas.push({pagina: <PointsWithContent data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }else if(data.pagesGeoInteligencia[i].pageName==="IconsWithDetail"){
            paginas.push({pagina: <IconsWithDetail data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }else if(data.pagesGeoInteligencia[i].pageName==="TwoSectionDetail"){
            paginas.push({pagina: <TwoSectionDetail data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }else if(data.pagesGeoInteligencia[i].pageName==="ImgWithBigText"){
            paginas.push({pagina: <ImgWithBigText data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }else if(data.pagesGeoInteligencia[i].pageName==="FooterGeogart"){
            paginas.push({pagina: <FooterGeogart data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }else if(data.pagesGeoInteligencia[i].pageName==="PointsWorld"){
            paginas.push({pagina: <PointsWorld data={data.pagesGeoInteligencia[i]} key={Math.random()}/>, order: data.pagesGeoInteligencia[i].order})
        }
    }
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    //console.log(paginas);
    return (
        <div className="geoInteligencia">
            <Header data={dataHeader} />
            <div>
                {paginas
                .sort((a, b) => a.order - b.order)
                .map((e) => e.pagina)}
            </div>
        </div>
    );
};
