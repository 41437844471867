// 2023-04-16 - Srodriguez - Agrega parametro de OrgId Global
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loading } from "../loading";
import puntoApi from "../../custom/axios/js/axios";
import Swal from "sweetalert2";
import data from "../../data/data.json";
import { Header } from "../header";
const huemulConfigInfo = require("../../data/config.json");

export const SendMail = (props) => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    };
    const serverEmail = (email) => {
        const lastIndex = email.lastIndexOf("@");
        return email.slice(lastIndex + 1, email.length);
    };
    const handleSendCode = async (event) => {
        event.preventDefault();
        if(validateEmail(email)){
            try {
                setIsLoading(true);
                const config = {
                    headers: {
                        Authorization:
                            "Basic " +
                            btoa(
                                email + ":" + huemulConfigInfo.orgId + ":EMAIL:::" + serverEmail(email)
                            ).toString("base64"),
                    },
                };

                // console.log(email);
                
                const response = await puntoApi.post(
                    "auth/v1/authValidateEmails",
                    {
                        validateEmail: email,
                        validateId: "",
                        validateKey: "",
                        validateStatus: "",
                        orgId: huemulConfigInfo.orgId // Asumiendo que se espera el orgId aquí
                    },
                    config // La configuración debería incluir solo headers
                );                
                // console.log(response);
                const data = response?.data?.data;
                console.log("Datos de la respuesto:", data);
                const tokenId = data?.[0]?.validateId; // Extraer validateId del primer elemento
                console.log("TokenId recibido:", tokenId);
                if (response.data.isSuccessful === true) {
                    setEmail("");
                    setIsLoading(false);
                    Swal.fire(
                        props.data.data.titleAlertConfirm,
                        props.data.data.messageAlertConfirm,
                        "success"
                    ).then(()=>{
                        navigate("/forgotPassword/tokenPassword", {
                            state:{
                                email: email,
                                tokenId: tokenId
                            }
                        });
                    });
                }else{
                    setEmail("");
                    setIsLoading(false);
                    Swal.fire(
                        props.data.data.titleAlertError,
                        props.data.data.messageAlertError,
                        "error"
                    );
                }
            } catch (err) {
                console.log(err);
                setIsLoading(false);
                Swal.fire(
                    props.data.data.titleAlertError,
                    props.data.data.messageAlertError,
                    "error"
                );
            }
        }else{
            setIsValidEmail(false);
            setTimeout(()=>{
                setIsValidEmail(true);
            },3000);
        }
    }
    const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    return(
        <div
            className="sendMail-container"
            style={{
                backgroundImage: "url('"+props.data.data.backgroundImg+"')",
            }}
        >
            <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
            <div className="sendMail-form">
                <div className="fadeIn register-img-logo">
                    <img
                    src={props.data.data.img}
                    className="img-fluid"
                    alt="Geogart-Icon"
                    style={{
                        maxHeight: props.data.data.maxHeight ?? "60px"
                    }}
                    />
                </div>
                <h2 className="sendMail-title">{props.data.data.title}</h2>
                <p className="sendMail-paragraph">{props.data.data.textEmail}</p>
                <form onSubmit={(e) => handleSendCode(e)} className="sendMail-form-object">
                    <input
                        type="text"
                        id="emailLogin"
                        className="register-input-all-width register-input-color-placeholder"
                        name="email"
                        placeholder={props.data.data.placeHolderEmail}
                        value={email}
                        onChange={onChangeEmail}
                        required
                    />
                    { !isValidEmail ? <p className="sendMail-error-format-email"> {props.data.data.messageIncorrectEmail}</p> : ""}
                    <div className="sendMail-container-buttons">
                        <button className="register-button register-button-register-account" value="Log In">
                            {props.data.data.textButton}
                        </button>
                    </div>
                </form>
                { isLoading && <Loading/>}
            </div>
        </div>
    )
}