// 2023-04-16 - Srodriguez - Agrega parametro de OrgId Global
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loading } from "../loading";
import { useLocation } from "react-router-dom";
import puntoApi from "../../custom/axios/js/axios";
import Swal from "sweetalert2";
import data from "../../data/data.json";
import { Header } from "../header";
// const huemulConfigInfo = require("../../data/config.json");

export const ChangePassword = (props) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const location = useLocation();
    if(!location.state){
        navigate("/");
    }
    const {email, token, validateId} = location.state || {};

    const onChangePassword = (event) => {
        setPassword(event.target.value);
    };
    const onChangePasswordConfirm = (event) => {
        setPasswordConfirm(event.target.value);
    };
    const validatePassword = (password, passwordConfirm) => {
        if(passwordConfirm === password){
            return true
        }
        return false;
    }
    const handleSendPassword = async (event) => {
        event.preventDefault();
    
        if (validatePassword(password, passwordConfirm)) {
            try {
                setIsLoading(true);
    
                const tokenBase64 = btoa(`${email}:${validateId}:${password}`);
                const config = {
                    headers: {
                        Authorization: "Basic " + tokenBase64,
                    },
                };
    
                console.log("Request Headers:", config.headers);
                console.log("Payload:", { email, token, password, passwordConfirm });
    
                const response = await puntoApi.post(
                    "/auth/v1/changePassword",
                    { email, verificationId: token, password, passwordConfirm },
                    config
                );
    
                console.log("API Response:", response.data);
    
                setIsLoading(false);
    
                // Manejo correcto de la respuesta
                if (response.data.isSuccessful) {
                    Swal.fire("Éxito", "La contraseña ha sido cambiada", "success").then(() => {
                        navigate("/login");
                    });
                } else {
                    throw new Error(response.data.message || "Error desconocido");
                }
            } catch (err) {
                console.error("Error al cambiar la contraseña:", err);
                setIsLoading(false);
    
                const errorMessage = err.response?.data?.message || "No se pudo cambiar la contraseña";
                Swal.fire("Error", errorMessage, "error");
            }
        } else {
            setIsValidPassword(false);
            setTimeout(() => setIsValidPassword(true), 3000);
        }
    };    
    
    
    return(
        <div
            className="sendMail-container"
            style={{
                backgroundImage: "url('"+props.data.data.backgroundImg+"')",
            }}
        >
            <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
            <div className="sendMail-form">
                <div className="fadeIn register-img-logo">
                    <img
                    src={props.data.data.img}
                    className="img-fluid"
                    alt="Geogart-Icon"
                    style={{
                        maxHeight: props.data.data.maxHeight ?? "60px"
                    }}
                    />
                </div>
                <h2 className="sendMail-title">{props.data.data.title}</h2>
                <p className="sendMail-paragraph">{props.data.data.textPassword}</p>
                <form onSubmit={(e) => handleSendPassword(e)} className="sendMail-form-object">
                    <input
                        type="password"
                        id="changePassword"
                        className="register-input-all-width register-input-color-placeholder"
                        name="password"
                        placeholder="Contraseña"
                        value={password}
                        onChange={onChangePassword}
                        
                        required
                    />
                    <input
                        type="password"
                        id="changePasswordConfirm"
                        className="register-input-all-width register-input-color-placeholder"
                        name="password"
                        placeholder="Contraseña"
                        value={passwordConfirm}
                        onChange={onChangePasswordConfirm}
                        
                        required
                    />
                    { !isValidPassword ? <p className="sendMail-text-email"> {props.data.data.messageIncorrectFormatPassword}</p> : ""}
                    <div className="sendMail-container-buttons">
                        <button className="register-button register-button-register-account" value="Log In">
                            {props.data.data.textButton}
                        </button>
                    </div>
                </form>
                { isLoading && <Loading/>}
            </div>
        </div>
    )
}