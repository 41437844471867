import React from "react";

export const ButtonWithArrow = (props) => {

  return (
    <a href={props.data.href}>
    <div class="button-with-arrow-container" 
        style={{
            backgroundColor : props.data.data.backgroundcolor ? props.data.data.backgroundcolor : "",
            color: props.data.data.colorText ?? ""
        }}>
        <span 
            class="button-with-arrow-container-p"
            style={{
                fontSize: props.data.data.fontSizeText ? props.data.data.fontSizeText : ""
            }}
        >
            {props.data.data.text}
        </span>
        {/*<p class="button-with-arrow-container-p" 
        style={{
            fontSize: props.data.data.fontSizeText ? props.data.data.fontSizeText : ""
        }}> {"props.data.data.text"} </p>*/}
        <div>
            {/*<i
                class={props.data.data.iconArrow ? props.data.data.iconArrow : "bx bx-up-arrow-alt"}
                style={{fontSize: props.data.data.fontSizeIcon ? props.data.data.fontSizeIcon : "40px"}}
            ></i>*/}
            <img
                alt={props.data.data.text}
                src={props.data.data.imgArrow}
                height={"44px"}
                width={"134px"}
            />
        </div>
        <br/>
    </div>
    </a>
  );
};
