import React, { /*useEffect*/ } from "react";
import { useLocation } from "react-router-dom";
//import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

export const FloatingFooter = (props) => {
  const location = useLocation();
  //const navigate = useNavigate();
  const pagesDefinition = require("../data/data.json");
  //const optionsToShow = pagesDefinition.pages.filter(page => page.showInMenu === true);
  const optionsToShowMenuFooter = pagesDefinition.pages.filter(page => page.showInMenuFooter === true);
  const capitalizeWords = (texto) => {
    let words = texto.toLowerCase().split(" ");
    for(let i=0;i<words.length;i++){
      words[i]=words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }
    let capitalizedText=words.join(" ");
    return capitalizedText;
  }
  /*const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };*/
  /*const detectInputsTouchMobile = () => {
    let inputsPage = document.getElementsByTagName("input");
    for(let i = 0; i < inputsPage.length; i++){
      inputsPage[i].addEventListener("focus", function(){
        let elemFloatingFooter = document.getElementById("floating-footer");
        elemFloatingFooter.style.display = "none";
      });
    }
    for(let i = 0; i < inputsPage.length; i++){
      inputsPage[i].addEventListener("blur", function(){
        let elemFloatingFooter = document.getElementById("floating-footer");
        if(window.innerWidth < 1080){
          elemFloatingFooter.style.display = "flex";
        }
      });
    }
  }
  const detectResizeFloatingFooter = () => {
    let elemFloatingFooter = document.getElementById("floating-footer");
    if(window.innerWidth < 1080){
      elemFloatingFooter.style.display = "flex";
    }else{
      elemFloatingFooter.style.display = "none";
    }
  }
  useEffect(()=>{
    detectInputsTouchMobile();
    window.addEventListener("resize", detectResizeFloatingFooter);
    return () => {
      window.removeEventListener("resize", detectResizeFloatingFooter);
    }
  },[]);*/
  return (
    <div
      // id={props.data ? props.data.href.substring(1, props.data.href.len) : ""}
      className="floating-footer-container "
      id="floating-footer"
      style={{
        backgroundColor: props.data ? props.data.data.color : "",
        display:
        (location.pathname === "/404")
            ? "none"
            : "",
      }}
    >
        <div className="header-footer-mobile-lineup"></div>
        <div className="header-footer-mobile-container">
        {
          optionsToShowMenuFooter.map((d,i)=>(
            <div key={d.title + "header-footer-mobile-item" + i.toString()}>
              {
                d.href.charAt(0)==="#" ?
                <a
                  href={((location.pathname=== "/login" || location.pathname==="/register") ? "/#footerGeogart": d.href)}
                  onClick={()=>{window.scrollTo(0, 0)}}
                  className="header-footer-mobile-item-container"
                >
                  <div className={"header-footer-mobile-item header-footer-mobile-item"+i.toString()}>
                    <img
                      className="img-fluid header-footer-mobile-item-img"
                      src={d.iconShowInFooterMenu}
                      alt={d.title}
                      style={{
                        maxHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                        minHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                      }}
                    />
                    <img
                      className="img-fluid header-footer-mobile-item-img-hover"
                      src={d.iconShowInFooterMenuHover}
                      alt={d.title}
                      style={{
                        maxHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                        minHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                      }}
                    />
                    
                    <p className="header-footer-mobile-item-text">{capitalizeWords(d.title)}</p>
                  </div>
                  {
                    props.data.data.showLineBetweenItems ?
                    <div className={"header-footer-mobile-item-separator header-footer-mobile-item-separator"+i.toString() +
                      (i === optionsToShowMenuFooter.length-1 ? " header-footer-mobile-item-separator-last" : "")}
                    >
                    </div>
                    :
                    ""
                  }
                </a>
                :
                <Link
                  to={ (d.title === "Home" ?  (localStorage.auth ? "/statePostulations" : "/") : d.href)}
                  onClick={()=>{window.scrollTo(0, 0)}}
                  className="header-footer-mobile-item-container"
                >
                  <div className={"header-footer-mobile-item header-footer-mobile-item"+i.toString()}>
                    <img
                      className="img-fluid header-footer-mobile-item-img"
                      src={((location.pathname === "/" && d.title === "Home")||(location.pathname === "/queHacemos" && d.title === "¿QUÉ HACEMOS?")||(location.pathname === "/geointeligencia" && d.title === "GEOINTELIGENCIA")||(location.pathname === "/contacto" && d.title === "CONTACTO")) ? d.iconShowInFooterMenuHover : d.iconShowInFooterMenu}
                      alt={d.title}
                      style={{
                        maxHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                        minHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                      }}
                    />
                    <img
                      className="img-fluid header-footer-mobile-item-img-hover"
                      src={d.iconShowInFooterMenuHover}
                      alt={d.title}
                      style={{
                        maxHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                        minHeight: props.data.data.maxHeightFooterMenuIcon ?? "",
                      }}
                    />
                    <p className="header-footer-mobile-item-text">{capitalizeWords(d.title)}</p>
                  </div>
                  {
                    props.data.data.showLineBetweenItems ?
                    <div className={"header-footer-mobile-item-separator header-footer-mobile-item-separator"+i.toString() +
                      (i === optionsToShowMenuFooter.length-1 ? " header-footer-mobile-item-separator-last" : "")}
                    >
                    </div>
                    :
                    ""
                  }
                </Link>
              }
            </div>
          ))
        }
        </div>
    </div>
  );
};
