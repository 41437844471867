//08/06/2023 v2 Enmanuelle
import React, {/* Component,*/ useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import { message } from "antd";
//import Popup from "reactjs-popup";
import { Link, useNavigate} from "react-router-dom";

import { Header } from "./header";
import data from "../data/data.json";
import puntoApi from "../custom/axios/js/axios";
import useAuth from "../hook/useAuth";

export const PostulationsProperties = (props) => {
  const dataProps = data.pages.filter((e) => e.pageName === "Postulations")[0];
  const navigate = useNavigate();
  // const location = useLocation();
  //Declarate Variable

  const [viewPublication, setViewPublication] = useState([]);
  //const [publications/*, setPublications*/] = useState([]);
  const { /*auth,*/ setAuth } = useAuth();
  //const authLocal = (auth !== undefined ? auth : JSON.parse(localStorage.getItem("auth")));
  const [countries, setCountry] = useState();
  const [operationType, setOperationType] = useState([]);
  const [status, setStatus] = useState([]);

  const [countryApi, setCountryApi] = useState("");
  const [statusApi, setStatusApi] = useState("");
  const [operationApi, setOperationApi] = useState("");
  //const [/*rangoMin,*/ setRangoMin] = useState("");
  //const [rangoMax, setRangoMax] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const [postulationsImportant, setPostulationsImportant] = useState([]);
  const [postulationsNoImportant, setPostulationsNoImportant] = useState([]);
  const [alreadySearch, setAlreadySearch] = useState(dataProps.data.hideFilters ? true : false);
  const numberFormat = function (number) {
    return new Intl.NumberFormat("de-DE").format(number);
  };
  useEffect(()=>{
    setTimeout(()=>{
      if(!localStorage.auth){
          navigate("/");
      }
    },0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  //onChange Filters
  const onChangeCountry = (event) => {
    setCountryApi(event.target.value);
  };
  const onChangeStatus = (event) => {
    setStatusApi(event.target.value);
  };
  const onChangeOperation = (event) => {
    setOperationApi(event.target.value);
  };
  const onChangeNameFilter = (event) => {
    setNameFilter(event.target.value);
  };
  const filterName = () => {
    if (!nameFilter) {
      setPostulationsImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adIsImportant)
        )
      );
      setPostulationsNoImportant(
        viewPublication?.filter((postulation) =>
          (!postulation.adIsImportant)
        )
      );
    } else {
      setPostulationsImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adName.toLowerCase().includes(nameFilter.toLocaleLowerCase()) && postulation.adIsImportant)
        )
      );
      setPostulationsNoImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adName.toLowerCase().includes(nameFilter.toLocaleLowerCase()) && !postulation.adIsImportant)
        )
      );
    }
  };
  useEffect(() => {
    filterName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const handleCleanFilter = () => {
    setAlreadySearch(false);
    document.getElementById("country").value = "";
    document.getElementById("status").value = "";
    document.getElementById("operation").value = "";
    setNameFilter("");
    setPostulationsImportant([]);
    setPostulationsNoImportant([]);
  };
  const handleSearch = () => {
    setAlreadySearch(true);
    if (!nameFilter) {
      setPostulationsImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adIsImportant)
        )
      );
      setPostulationsNoImportant(
        viewPublication?.filter((postulation) =>
          (!postulation.adIsImportant)
        )
      );
    } else {
      setPostulationsImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adName.toLowerCase().includes(nameFilter.toLocaleLowerCase()) && postulation.adIsImportant)
        )
      );
      setPostulationsNoImportant(
        viewPublication?.filter((postulation) =>
          (postulation.adName.toLowerCase().includes(nameFilter.toLocaleLowerCase()) && !postulation.adIsImportant)
        )
      );
    }
  }
  
  useEffect(() => {

    setAuth(JSON.parse( localStorage.getItem("auth")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    filterName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);


  useEffect(() => {

    puntoApi.get("/camp/filters/v1").then((response) => {
      // console.log(response.data.data)
      const responseData = response.data?.data[0];
      setCountry(responseData?.country || []);
      setOperationType(responseData?.operationType || []);
      setStatus(responseData?.statusApply || []);
    }).catch((error) => {
      console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const urlPublication = `/campBroker/byBroker/v1?countryId=${countryApi}&operationTypeId=${operationApi}&statusIdApply=${statusApi}`;
    async function fetchApi() {
      let authLocalStorage = localStorage.getItem("auth");
      if(authLocalStorage){
        const token = JSON.parse(authLocalStorage).token;
        //token.headers.authorization = "a11a2a22a2";
        try{
          const response = await puntoApi.get(urlPublication, token);
          // console.log(response.data.data);
          if(response.status === 200){
            setViewPublication(response.data.data);
            if(dataProps.data.hideFilters){
              setAlreadySearch(true);
              // console.log("entra");
              setPostulationsImportant(
                response.data.data.filter((postulation) =>
                  (postulation.adIsImportant)
                )
              );
              setPostulationsNoImportant(
                response.data.data.filter((postulation) =>
                  (!postulation.adIsImportant)
                )
              );
            }
          }else{
            message.success("No se encontraron postulaciones");
          }
        }catch(err){
          message.success("No se encontraron postulaciones");
        }
      }
    }
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryApi, operationApi, statusApi]);
  // console.log(postulationsImportant);
  // console.log(postulationsNoImportant);
  return (
    <div
      className="register-container"
        style={{
        backgroundImage: "url('"+dataProps.data.backgroundImg+"')",
      }}
    >
      {!props.data ? (
        <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
      ) : (
        <div />
      )}
      <div className="publication-container">
        <div className="section-title-prop">
          {
            dataProps.data.imgIcon ?
            <img
              alt="title-img"
              src={dataProps.data.imgIcon}
              className="img-fluid publicacions-title-img"
              style={{
                maxHeight: dataProps.data.maxHeightIcon ?? ""
              }}
            />
            :
            ""
          }
          <h2
            className="publications-title"
            style={{
              marginTop: dataProps.data.hideFilters ? "40px" : ""
            }}
          >
            {props.data ? props.data.data.titlePage : dataProps.data.titlePage}
          </h2>
        </div>
        {
          dataProps.data.hideFilters ?
          ""
          :
          <div className="filters__sections-container">
            <div className="filters__section">
              <div className="filters__subsection filters__subsection-postulations">
                <select
                  className="filters__toggle btn-filter"
                  id="country"
                  onChange={onChangeCountry}
                >
                  <option className="cointainerSelection" key="key2" value="">
                    {props.data
                      ? props.data.data.firstSelect
                      : dataProps.data.firstSelect}
                  </option>
                  {countries?.map((country) => (
                    <option
                      className="cointainerSelection"
                      key={country.countryId}
                      value={country.countryId}
                    >
                      {" "}
                      {country.countryName}{" "}
                    </option>
                  ))}
                </select>
                <select
                  className="filters__toggle btn-filter"
                  id="status"
                  onChange={onChangeStatus}
                >
                  <option className="cointainerSelection" key="key1" value="">
                    {props.data
                      ? props.data.data.secondSelect
                      : dataProps.data.secondSelect}
                  </option>
                  {status?.map((status) => (
                    <option
                      className="cointainerSelection"
                      key={status.statusId}
                      value={status.statusId}
                    >
                      {" "}
                      {status.statusName}{" "}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filters__subsection filters__subsection-postulations">
                <select
                  className="filters__toggle btn-filter"
                  id="operation"
                  onChange={onChangeOperation}
                >
                  <option className="cointainerSelection" key="key3" value="">
                    {props.data
                      ? props.data.data.thirdSelect
                      : dataProps.data.thirdSelect}
                  </option>
                  {operationType?.map((operationType) => (
                    <option
                      className="cointainerSelection"
                      key={operationType.operationTypeId}
                      value={operationType.operationTypeId}
                    >
                      {" "}
                      {operationType.operationTypeName}{" "}
                    </option>
                  ))}
                </select>
                <input
                  className="filters__toggle input-filter publication-filter-last"
                  placeholder="Nombre Publicación"
                  onChange={onChangeNameFilter}
                  value={nameFilter}
                />
              </div>
            </div>
          </div>
        }
        {
          dataProps.data.hideFilters ?
          ""
          :
          <div className="publications-buttons">
            <button
              className="publications-buttons-button publications-buttons-button-search publications-buttons-button-desktop"
              onClick={() => handleSearch()}
            >
              {props.data
                ? props.data.data.buttonSearch
                : dataProps.data.buttonSearch}
            </button>
            <button
              className="publications-buttons-button publications-buttons-button-clean publications-buttons-button-desktop"
              onClick={() => handleCleanFilter()}
            >
              {props.data
                ? props.data.data.buttonClean
                : dataProps.data.buttonClean}
            </button>

            <button
              className="publications-buttons-button publications-buttons-button-clean publications-buttons-button-mobile publications-buttons-button-mobile-clean"
              onClick={() => handleCleanFilter()}
            >
              {props.data
                ? props.data.data.buttonClean
                : dataProps.data.buttonClean}
            </button>
            <button
              className="publications-buttons-button publications-buttons-button-search publications-buttons-button-mobile publications-buttons-button-mobile-search"
              onClick={() => handleSearch()}
            >
              {props.data
                ? props.data.data.buttonSearchMobile
                : dataProps.data.buttonSearchMobile}
            </button>
          </div>
        }
        <div className="publications-container">
          <div className="publications-container-important">
            {postulationsImportant?.map((publication, index) => (
              <div className="publications-container-important-items">
                <Link
                  className="publication-wrap-box"
                  key={"publication-wrap-box" + index.toString()}
                  to={`/postulation/details?postId=${publication.postId}`}
                >
                  <div className="publication-wrap-box__title">
                    <div className="publication-wrap-box__title-img">
                      <img
                        src={publication.operationTypeName==="Compra" ? dataProps.data.iconBuy : dataProps.data.iconArriend}
                        alt="icon-publication"
                        className="img-fluid"
                        style={{
                          maxHeight: dataProps.data.maxHeightIconPublications ?? ""
                        }}
                      />
                    </div>
                    <div>
                      <p className="publication-wrap-box__title-operation">{publication.operationTypeName}</p>
                      <h4 className="publication-wrap-box__title-adName">{publication.adName}</h4>
                      <span className="location">
                        <span className="tolltipster">
                          {publication.countryId === "CL" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/cl_mini.png"})`,
                              }}
                            ></span>
                          ) : publication.countryId === "AR" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/ar_mini.png"})`,
                              }}
                            ></span>
                          ) : null}
                        </span>
                        {publication.countryName}, {publication.cityName} -{" "}
                        {numberFormat(publication.adM2From)}m² a{" "}
                        {numberFormat(publication.adM2To)}m² -{" "}
                        {publication.adZone}
                      </span>
                    </div>
                    
                  </div>
                </Link>
                {
                  index!==postulationsImportant.length-1 ?
                  <div className="publication-wrap-box-separator"></div>
                  :
                  ""
                }
              </div>
            ))}
          </div>
          <div className="publications-container-noImportant">
            {postulationsNoImportant?.map((publication, index) => (
              <div className={"publications-container-noimportant-items " + (index % 2 !== 0 ? "publications-container-noimportant-items-par": "")}>
                <Link
                  className={"publication-wrap-box-no-important"}
                  key={"publication-wrap-box" + index.toString()}
                  to={`/postulation/details?adId=${publication.adId}`}
                >
                  <div className="publication-wrap-box__title">
                    <div className="publication-wrap-box__title-img">
                      <img
                        src={publication.operationTypeName==="Compra" ? dataProps.data.iconBuyNoImportant : dataProps.data.iconArriendNoImportant}
                        alt="icon-publication"
                        className="img-fluid"
                        style={{
                          maxHeight: dataProps.data.maxHeightIconPublications ?? ""
                        }}
                      />
                    </div>
                    <div>
                      <p className="publication-wrap-box__title-operation publication-wrap-box__title-operation-noImportant">{publication.operationTypeName}</p>
                      <h4 className="publication-wrap-box__title-adName publication-wrap-box__title-adName-noImportant">{publication.adName}</h4>
                      <span className="location">
                        <span className="tolltipster">
                          {publication.countryId === "CL" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/cl_mini.png"})`,
                              }}
                            ></span>
                          ) : publication.countryId === "AR" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/ar_mini.png"})`,
                              }}
                            ></span>
                          ) : null}
                        </span>
                        {publication.countryName}, {publication.cityName} -{" "}
                        {numberFormat(publication.adM2From)}m² a{" "}
                        {numberFormat(publication.adM2To)}m² -{" "}
                        {publication.adZone}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          {
            alreadySearch ?
              <div>
                {
                  postulationsImportant.length === 0 && postulationsNoImportant.length === 0 ?
                  <Link
                    className="postulation-not-found"
                    key={""}
                    to={`/publications`}
                  >
                    <div className="postulation-not-found-container">
                      {
                        dataProps.data.hideFilters ?
                        ""
                        :
                        <p className="postulation-not-found-text">{dataProps.data.nonePostulation ?? "No se encontró ninguna postulación."}</p>
                      }
                    </div>
                  </Link>
                  :
                  ""
                }
              </div>
            :
            <div className="postulation-not-found-container">
              <p className="postulation-not-found-text">{dataProps.data.noSearchYet ?? "Ingrese los filtros que desee para ver sus postulaciones."}</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
