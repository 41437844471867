// 2023-04-16 - Srodriguez - Agrega parametro de OrgId Global
import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Loading } from "../loading";
import { useLocation } from "react-router-dom";
import puntoApi from "../../custom/axios/js/axios";
import Swal from "sweetalert2";
import data from "../../data/data.json";
import { Header } from "../header";

export const TokenPassword = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [token, setToken] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isValidToken, setIsValidToken] = useState(true);
    const { email, tokenId } = location.state || {}; // Obtén los valores desde el estado

    // Validar que los datos existan
    if (!email || !tokenId) {
        Swal.fire(
            "Error",
            "Faltan datos necesarios para continuar. Por favor, vuelve a iniciar el proceso.",
            "error"
        );
        navigate("/forgotPassword"); // Redirigir al paso anterior
        return null; // Detener la renderización
    }

    const onChangeToken = (event) => {
        setToken(event.target.value);
    };

    const handleSendToken = async (event) => {
        event.preventDefault();
    
        if (token.length === 5) {
            try {
                setIsLoading(true);
                const config = {
                    headers: {
                        Authorization:
                            "Basic " +
                            btoa(`${token}:${tokenId}:TOKEN:::`).toString("base64"),
                    },
                };
    
                const response = await puntoApi.put(
                    "/auth/v1/authValidateEmails",
                    {
                        validateEmail: email, // Usar el email del estado
                        validateId: tokenId,  // Usar el validateId del estado
                        validateKey: token,
                        validateStatus: "",
                        orgId: props.data.data.orgId,
                    },
                    config
                );
    
                setIsLoading(false);
    
                // Actualizar la condición de éxito
                if (response.data.isSuccessful === true) {
                    setToken("");
                    Swal.fire(
                        props.data.data.titleAlertConfirm,
                        props.data.data.messageAlertConfirm,
                        "success"
                    ).then(() => {
                        navigate("/forgotPassword/tokenPassword/changePassword", {
                            state: {
                                email: email,
                                token: token,
                                validateId: tokenId,
                            },
                        });
                    });
                } else {
                    // Si el campo isSuccessful no es true, lanza el error
                    throw new Error(response.data.message || "Token inválido");
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
                Swal.fire(
                    props.data.data.titleAlertError,
                    props.data.data.messageAlertError,
                    "error"
                );
            }
        } else {
            setIsValidToken(false);
            setTimeout(() => {
                setIsValidToken(true);
            }, 3000);
        }
    };
    

    return (
        <div
            className="sendMail-container"
            style={{
                backgroundImage: "url('" + props.data.data.backgroundImg + "')",
            }}
        >
            <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
            <div className="sendMail-form">
                <div className="fadeIn register-img-logo">
                    <img
                        src={props.data.data.img}
                        className="img-fluid"
                        alt="Geogart-Icon"
                        style={{
                            maxHeight: props.data.data.maxHeight ?? "60px",
                        }}
                    />
                </div>
                <h2 className="sendMail-title">{props.data.data.title}</h2>
                <p className="sendMail-paragraph">{props.data.data.textToken}</p>
                <form onSubmit={handleSendToken} className="sendMail-form-object">
                    <input
                        type="text"
                        id="tokenPassword"
                        className="register-input-all-width register-input-color-placeholder"
                        name="token"
                        placeholder="Token"
                        value={token}
                        maxLength={5}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        onChange={onChangeToken}
                        required
                    />
                    {!isValidToken && (
                        <p className="sendMail-error-format-email">
                            {props.data.data.messageIncorrectFormatToken} {props.data.data.lengthToken ?? "5"}
                        </p>
                    )}
                    <div className="sendMail-container-buttons">
                        <button
                            className="register-button register-button-register-account"
                            onClick={handleSendToken}
                            disabled={token.length !== 5}
                        >
                            {props.data.data.textButton}
                        </button>
                    </div>
                </form>
                {isLoading && <Loading />}
            </div>
        </div>
    );
};
