// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useEffect } from "react";

export const IconsWithDetail = (props) => {
    const detectScrollIconsWithDetail = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemTitle=document.getElementById("iconsWithDetails-container-title"+props.data.order.toString());
        if(!elem.classList.contains("animation_showed")){
            if(isVisible){
                if(elemTitle!==null){
                    elemTitle.classList.remove("animate__fadeOutLeft");
                    elemTitle.classList.add("animate__fadeInLeft");
                }
                for(let i=0;i<props.data.data.items.length;i++){
                    let elemBox=document.getElementById("iconsWithDetails-container-items-item"+i.toString()+props.data.order.toString());
                    elemBox.classList.remove("animate__fadeOut");
                    elemBox.classList.add("animate__fadeInUp");
                }
                elem.classList.add("animation_showed");
            }else{
                if(elemTitle!==null){
                    elemTitle.classList.remove("animate__fadeInLeft");
                    elemTitle.classList.add("animate__fadeOutLeft");
                }
                for(let i=0;i<props.data.data.items.length;i++){
                    let elemBox=document.getElementById("iconsWithDetails-container-items-item"+i.toString()+props.data.order.toString());
                    elemBox.classList.remove("animate__fadeInUp");
                    elemBox.classList.add("animate__fadeOut");
                }
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollIconsWithDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollIconsWithDetail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
            className="iconsWithDetails"
        >

            <div className="iconsWithDetails-container">
                {
                    props.data.data.title ?
                    <div className="iconsWithDetails-container-title" id={"iconsWithDetails-container-title"+props.data.order.toString()}>
                        <h2
                            className={"iconsWithDetails-title animate__animated animate__fadeInLeft iconsWithDetail-animation-duration"+
                                        (props.data.data.isItemsList ? " iconsWithDetails-title-padding-bottom" : "")}
                            dangerouslySetInnerHTML={{
                                __html: props.data.data.title
                            }}
                        />
                        <h2
                            className="iconsWithDetails-title iconsWithDetails-title2 animate__animated animate__fadeInLeft iconsWithDetail-animation-duration"
                            dangerouslySetInnerHTML={{
                                __html: props.data.data.title2
                            }}
                        />
                    </div>
                    :
                    ""
                }
                <div className={"iconsWithDetails-container-items" + (props.data.data.isItemsList ? " iconsWithDetails-container-item-start" : "") +
                                (props.data.data.showLineBetweenItems ? " iconsWithDetails-container-item-center" : "")}>
                    {
                        props.data.data.items.map((item,i)=>(
                            <div
                                className={"col-lg-3 iconsWithDetails-container-items-item animate__animated animate__fadeInUp iconsWithDetail-animation-duration"+
                                (i===props.data.data.items.length-1 ? " iconsWithDetails-container-items-item-no-lineright": "")}
                                id={"iconsWithDetails-container-items-item"+i.toString()+props.data.order.toString()}
                                key={"iconsWithDetails-container-items-item"+i.toString()}
                            >
                                <div className={"iconsWithDetails-container-item iconsWithDetails-container-item"+i.toString() + (props.data.data.showLineBetweenItems ? " iconsWithDetails-container-item-yesLeft": "")}>
                                    <img
                                        alt={item.title}
                                        src={item.src}
                                        className="img-fluid iconsWithDetails-container-items-item-img"
                                        style={{
                                            maxHeight: props.data.data.maxHeightIcons ?? ""
                                        }}
                                    />
                                    {
                                        props.data.data.isItemsList ?
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column"
                                        }}>
                                            <img
                                                alt="img-arrow-icon"
                                                className="img-fluid iconsWithDetails-container-items-item-arrow-img"
                                                src={props.data.data.imgIconArrow}
                                                style={{
                                                    maxHeight: props.data.data.maxHeightImgIconArrow ?? "",
                                                    marginTop: "15px"
                                                }}
                                            />
                                            {
                                                props.data.data.itemsList[i].list.map((list,j)=>(
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                            flexDirection: "column",
                                                            minHeight: "90px"
                                                        }}
                                                        key={"iconsWithDetails-container-items-item-list"+i.toString()+j.toString()}
                                                    >
                                                        <p
                                                            className="iconsWithDetails-container-items-item-paragraph"
                                                            dangerouslySetInnerHTML={{
                                                                __html: list.text ? list.text : "",
                                                            }}
                                                            style={{
                                                                fontSize: props.data.data.fontSizeText ?? ""
                                                            }}
                                                        />
                                                        <hr className={"iconsWithDetails-hr iconsWithDetails-hr"+j.toString()}/>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        :
                                        <div>
                                            <h4 className="iconsWithDetails-container-items-item-title">
                                            {item.title}
                                            </h4>
                                            <p
                                                className={"iconsWithDetails-container-items-item-paragraph" +
                                                        (props.data.data.showLineBetweenItems ? " iconsWithDetails-container-items-item-paragraph-with-separator" : "")}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.paragraph ? item.paragraph : "",
                                                }}
                                            />
                                        </div>
                                    }
                                </div>
                                {
                                    props.data.data.showLineBetweenItems ?
                                    <div
                                        className={"iconsWithDetail-line-vertical iconsWithDetail-line-vertical"+i.toString()}
                                        style={{
                                            display: i===props.data.data.items.length-1 ? "none" : ""
                                        }}
                                    ></div>
                                    :
                                    ""
                                }
                            </div>
                        ))
                    }
                </div>
                <div className="iconsWithDetails-container-hr">
                    {
                        props.data.data.showLineDown ?
                        <hr className="iconsWithDetails-hr"/>
                        :
                        ""
                    }
                </div>
            </div>
        </section>
    );
};
