//27/04/2023 v2.0.0 - Enmanuelle
import { useNavigate, useLocation, Link } from "react-router-dom";
import React, { useEffect } from "react";

import useAuth from "../hook/useAuth";
import { useState } from "react";
import puntoApi from "../custom/axios/js/axios";
import { message } from "antd";
import { Loading } from "./loading";

export const Header = (props) => {
  const pagesDefinition = require("../data/data.json");
  const optionsToShow = pagesDefinition.pages.filter(page => page.showInMenu === true);
  //const optionsToShowMenuFooter = pagesDefinition.pages.filter(page => page.showInMenuFooter === true);
  const { auth, setAuth } = useAuth();
  //const [isOpenList , setIsOpenList ] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [auxUserEmail] = useState({email: ""});
  const [auxUserPassword] = useState({password: ""});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const adId = localStorage.getItem("adId");
  /*const detectResizeVertical = () => {
    //let elem = document.getElementById("header-footer-mobile-id");
    console.log(elem.style.bottom);
    if(window.innerWidth < 1080){
      /*elem.style.display = "none";
      elem.style.display = "flex";
      elem.style.bottom = 0;
    }
  }
  useEffect(()=>{
    window.addEventListener("scroll",detectResizeVertical);
    return () => {
      window.removeEventListener("scroll",detectResizeVertical);
    }
  },[])*/
  const onChangeUserPassword = (event) => {
    setUserPassword(event.target.value);
    auxUserPassword.password=event.target.value;
  }
  const onChangeUserEmail = (event) => {
    setUserEmail(event.target.value);
    auxUserEmail.email=event.target.value;
  }
  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/login", {
      state:
        location.search === ""
          ? `${location.pathname}`
          : `${location.pathname}${location.search}`,
    });
  };
  /*const handleRegister = (e) => {
    e.preventDefault();
    navigate("/register");
  };*/
  const handleLogout = (e) => {
    //e.preventDefault();
    setAuth(null);
    localStorage.removeItem("auth");
    navigate("/");
  };
  const detectScrollHeader = () => {
    let elemLogoHeader = document.getElementById("header-logo-only-mobile-scroll");
    let scrollPosition = window.scrollY;
    if(window.innerWidth > 1080){
      if(scrollPosition > 200){
        elemLogoHeader.classList.add("header-logo-only-mobile-scroll", "animate__animated", "animate__fadeIn");
      }else{
        elemLogoHeader.classList.remove("header-logo-only-mobile-scroll");
        elemLogoHeader.classList.remove("animate__fadeIn");
        
      }
    }
  }
  useEffect(()=>{
    window.addEventListener("scroll", detectScrollHeader);
    return () => {
      window.removeEventListener("scroll", detectScrollHeader);
    };
  },[]);

  //login part

  const serverEmail = (email) => {
    const lastIndex = email.lastIndexOf("@");
    return email.slice(lastIndex + 1, email.length);
  };
  const success = () => {
    message.success("Has ingresado correctamente");
  };
  /*const capitalizeWords = (texto) => {
    let words = texto.toLowerCase().split(" ");
    for(let i=0;i<words.length;i++){
      words[i]=words[i].charAt(0).toUpperCase() + words[i].substring(1);
    }
    let capitalizedText=words.join(" ");
    return capitalizedText;
  }*/
  //console.log(location);
  /*const error = () => {
    message.error("Ha ocurrido un error, verifica tu email y/o contraseña");
  };*/
  const handleLoginUser = async (email,password) => {
    setIsLoading(true);
    try {
      const config = {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              email + ":GEODART:EMAIL:::" + serverEmail(email) + ":" + password
            ).toString("base64"),
        },
      };

      const response = await puntoApi.post(
        "/auth/v1/sign-in-provider",
        config,
        config
      );
      const data = response?.data?.data;
      //console.log(data);
      if (response.data.isSuccessful === true) {
        success();
        const accessToken = data.map((data) => data.token);
        const userName = data.map((data) => data.displayName);
        console.log("Usuario", userName);
        console.log("Datos", data);
        // console.log("Token", accessToken);
        setUserEmail("");
        auxUserEmail.email="";
        setUserPassword("");
        auxUserPassword.password="";
        setIsLoading(false);
        const token = {
          headers: {
            orgId: "GEODART",
            Authorization: "Bearer " + accessToken,
            accessToken: accessToken,
          },
        };
        const dataInfo = {
          userName: userName[0],
          token: token,
        };
        console.log("DataInfo", dataInfo);
        setAuth(dataInfo);
        localStorage.setItem("auth", JSON.stringify(dataInfo));
        localStorage.setItem("lastDateDataBack",new Date());
        if(sessionStorage.pathAfterLogin){
          let auxPath=sessionStorage.pathAfterLogin;
          sessionStorage.removeItem("pathAfterLogin");
          navigate(auxPath);
        }else{
          navigate("/statePostulations");
        }
        if (adId != null) {
          localStorage.removeItem("adId");
        }
      } 
    } catch (err) {
      setIsLoading(false);
      // Manejo del error 401 Unauthorized
      if (err.response && err.response.status === 401) {
        message.error("La cuenta aún no ha sido aprobada.");
      } else {
        message.error("Error al iniciar sesión. Revise el email y la contraseña.");
      }
    }
  };
  useEffect(() => {
    // if (auth?.userName === undefined && localStorage.getItem("auth") !== null) {
    //   setAuth(JSON.parse(localStorage.getItem("auth")));
    // }
    let inputPassword = document.getElementById("header-input-password");
    if(inputPassword){

      inputPassword.addEventListener("keypress", (event) => {
        if(event.key==="Enter"){
          event.preventDefault();
          //console.log(userEmail);
          //console.log(userPassword);
          //console.log(auxUserEmail.email);
          //console.log(auxUserPassword.password);
          handleLoginUser(auxUserEmail.email, auxUserPassword.password);
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;
      
      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };
    /*const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };*/

    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );

    let selectHeader = select("#header");
    
    if (selectHeader && !pagesDefinition.showMaintainPage) {
      /*let headerOffset = selectHeader.offsetTop;
      let nextElement = selectHeader.nextElementSibling;
      const headerFixed = () => {
        if (headerOffset - window.scrollY <= 0) {
          selectHeader.classList.add("fixed-top");
          //nextElement.classList.add("scrolled-offset");
        } else {
          selectHeader.classList.remove("fixed-top");
          //nextElement.classList.remove("scrolled-offset");
        }
      };
      window.addEventListener("load", headerFixed);
      onscroll(document, headerFixed);*/
    }

    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });

    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(this.hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(()=>{
    /*let selectHeader = document.querySelector("#header");
    if(selectHeader){
      let nextElement = selectHeader.nextElementSibling;
      selectHeader.classList.add("fixed-top");
      nextElement.classList.add("scrolled-offset");
    }*/
  },[]);
  return (
    <header
      id={props.data ? props.data.href.substring(1, props.data.href.len) : ""}
      className="d-flex align-items-center"
      style={{
        backgroundColor: location.pathname === "/" ? (props.data.data.defaultBackgroundColor ?? "") :
        (props.data.data.backgroundcolor ? props.data.data.backgroundcolor : ""),
        borderRadius: props.data.data.borderRadiusHeader ? props.data.data.borderRadiusHeader : 0,
        
      }}
    >
      {isLoading ?
          <Loading />
          :
          ""
        }
      <div className="header-container">
        <div className={"d-flex align-items-center " + (props.data.data.showLogo ? "header-with-logo" : "header-no-logo")}>
          {
            props.data.data.showLogo ?
            <div className="logo">
              <h1 className="text-light">
                <a href="/">
                  <span>
                    <img
                      id="header-logo-only-mobile-scroll"
                      className="img header-logo-only-mobile"
                      src={props.data ? props.data.data.logoNav : ""}
                      alt="Imagen Logo"
                      height={props.data.data.heightImg ? props.data.data.heightImg : ""}
                      width ={props.data.data.widthImg ? props.data.data.widthImg : ""}
                    />
                    {props.data ? props.data.data.title : ""}
                  </span>
                </a>
              </h1>
            </div>
            :
            ""
          }
          <div className="header-options-container">
            <div className="header-options-mobile-login">
              <button
                className="header-options-button-register"
                style={{
                  fontFamily: props.data.data.familyFontOptions ?? "",
                  fontSize: props.data.data.fontSizeOptions ?? ""
                }}
                onClick={()=>{
                  if(auth?.userName){
                    handleLogout();
                  }else{
                    navigate("/login");
                  }
                }}
              >
                {auth?.userName ? props.data.data.textButtonLogout : props.data.data.textButtonIngresar}
              </button>
            </div>
            <nav id="navbar" className={"navbar header-navbar" + (props.data.data.showLogo ? "" : " header-options-margin")}>
              <ul>
                {optionsToShow
                  .map((d, i) => (
                    <li key={`${d.title}-${i}`}>
                      {d.title === "Home" ? (
                        <a
                          className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")}
                          data-toggle="collapse"
                          href={ auth?.userName ? "/statePostulations" : "/"}
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                              fontFamily: props.data.data.familyFontOptions ?? "",
                              fontSize: props.data.data.fontSizeOptions ?? ""
                            }}
                            className="item-header"
                          >
                            {d.title}
                            {
                              props.data.data.separationBetweenOptions === true?
                              <span className="header-options-separation">|</span>
                              :
                              ""
                            }
                          </span>
                        </a>
                      ) :
                      d.title === "Login" ? (
                        !auth?.userName ? (
                          
                          <Link
                            to={"/login"}
                            data-toggle="collapse"
                            className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")
                            + (props.data.data.showLoginDown ? " header-option-login-show-down" : "")}
                            onClick={(e) => handleLogin(e)}
                          >
                            <span
                              style={{
                                color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                                fontFamily: props.data.data.familyFontOptions ?? "",
                                fontSize: props.data.data.fontSizeOptions ?? ""
                              }}
                              className="item-header"
                            >
                              {
                                d.data.imgIconHeader ?
                                <img
                                  alt={d.title}
                                  src={d.data.imgIconHeader}
                                  className="img-fluid item-header-icon"
                                  width={"17px"}
                                  height={"17px"}
                                />
                                : d.data.iconHeader ?
                                <i className={d.data.iconHeader + " item-header-icon"} style={{color: props.data.data.iconsColor ? props.data.data.iconsColor : ""}}></i>
                                :
                                ""
                              }
                              {d.title}
                              {
                                props.data.data.separationBetweenOptions === true?
                                <span className="header-options-separation">|</span>
                                :
                                ""
                              }
                            </span>
                          </Link>
                        ) : null
                      ) : d.title === "Registrate" ? (
                        !auth?.userName ? (
                          <Link
                            to={"/register"}
                            data-toggle="collapse"
                            className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")
                            + (props.data.data.showRegisterDown ? " header-option-register-show-down" : "")}
                          >
                            <span
                              style={{
                                color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                                fontFamily: props.data.data.familyFontOptions ?? "",
                                fontSize: props.data.data.fontSizeOptions ?? ""
                              }}
                              className="item-header"
                            >
                            {
                              d.data.imgIconHeader ?
                              <img
                                alt={d.title}
                                src={d.data.imgIconHeader}
                                className="img-fluid item-header-icon"
                                width={"17px"}
                                height={"17px"}
                              />
                              : d.data.iconHeader ?
                              <i className={d.data.iconHeader + " item-header-icon"} style={{color: props.data.data.iconsColor ? props.data.data.iconsColor : ""}}></i>
                              :
                              ""
                            }
                              {d.title}
                              {
                                props.data.data.separationBetweenOptions === true?
                                <span className="header-options-separation">|</span>
                                :
                                ""
                              }
                            </span>
                          </Link>
                        ) : null
                      ) : d.title === "Cerrar Sesión" ? (
                        auth?.userName ? 
                        <a
                          className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")}
                          data-toggle="collapse"
                          onClick={handleLogout}
                          href="/"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            style={{
                              color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                              fontFamily: props.data.data.familyFontOptions ?? "",
                              fontSize: props.data.data.fontSizeOptions ?? ""
                            }}
                            className="item-header"
                          >
                            {
                              props.data.data.separationBetweenOptions === true?
                              <span className="header-options-separation">|</span>
                              :
                              ""
                            }
                            {d.title}
                          </span>
                        </a>
                        :
                        ""
                      ) : d.title === "Mis Postulaciones" ? (
                        auth?.userName ?
                          <a
                            className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")}
                            data-toggle="collapse"
                            href="/postulations"
                            style={{ color: "#1657BE", cursor: "pointer" }}
                          >
                            <span
                              style={{
                                color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                                fontFamily: props.data.data.familyFontOptions ?? "",
                                fontSize: props.data.data.fontSizeOptions ?? ""
                              }}
                              className="item-header"
                            >
                              {
                                d.data.iconHeader ?
                                <i
                                  className="bx bx-building-house item-header-icon"
                                  style={{color: props.data.data.iconsColor ? props.data.data.iconsColor : "#000000"}}>
                                </i>
                                :
                                ""
                              }
                              Postulaciones
                              {
                                props.data.data.separationBetweenOptions === true?
                                <span className="header-options-separation">|</span>
                                :
                                ""
                              }
                            </span>
                          </a>
                        :
                        ""
                      ) : d.title === "Avisos" ? (
                        auth?.userName ?
                          <a
                            className={"nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")}
                            data-toggle="collapse"
                            href={d.href}
                            style={{ color: "#1657BE", cursor: "pointer" }}
                          >
                            <span
                              style={{
                                color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                                fontFamily: props.data.data.familyFontOptions ?? "",
                                fontSize: props.data.data.fontSizeOptions ?? ""
                              }}
                              className="item-header"
                            >
                              {
                                d.data.iconHeader ?
                                <i
                                  className="bx bx-building-house item-header-icon"
                                  style={{color: props.data.data.iconsColor ? props.data.data.iconsColor : "#000000"}}>
                                </i>
                                :
                                ""
                              }
                              Avisos
                              {
                                props.data.data.separationBetweenOptions === true?
                                <span className="header-options-separation">|</span>
                                :
                                ""
                              }
                            </span>
                          </a>
                        :
                        ""
                      ) : (
                        <a
                          data-toggle="collapse"
                          className={"nav-link scrollto active nav-link collapsed dropdown" + (props.data.data.separationBetweenOptions ? " header-option-show-separation" : "")}
                          href={`${
                            (auth?.userName ? (d.href === "#footerGeogart" ? "/contacto" : (d.href.substring(0, 1) === "#" ? "/" + d.href : d.href)): (d.href.substring(0, 1) === "#" ? "/" + d.href : d.href))
                          }`}
                        >
                          <span
                            style={{
                              color: i % 2 === 0 ? props.data.data.itemsFontColorOne : props.data.data.itemsFontColorTwo,
                              fontFamily: props.data.data.familyFontOptions ?? "",
                              fontSize: props.data.data.fontSizeOptions ?? ""
                            }}
                            className="item-header"
                          >
                          {
                              d.data.imgIconHeader ?
                              <img
                                alt={d.title}
                                src={d.data.imgIconHeader}
                                className="img-fluid item-header-icon"
                                width={"17px"}
                                height={"17px"}
                              />
                              : d.data.iconHeader ?
                              <i className={d.data.iconHeader + " item-header-icon"} style={{color: props.data.data.iconsColor ? props.data.data.iconsColor : ""}}></i>
                              :
                              ""
                            }
                              {d.title}
                              {
                                (d.title === "Contacto") && (props.data.data.separationBetweenOptions === true)?
                                ""
                                :
                                <span className="header-options-separation">|</span>
                              }
                          </span>
                        </a>
                      )}
                    </li>
                  ))}
              </ul>
              <i id="iconMenu" className="bi bi-list mobile-nav-toggle"
                style={{
                  color:"#FFFFFF"
                }}
              >
              </i>
            </nav>
            {
              props.data.data.showLoginDown || props.data.data.showRegisterDown ? (
                localStorage.getItem("auth") ? (
                  <div className="header-options-login-register-container">
                    <span
                      className="header-options-user-name"
                      style={{
                        fontFamily: props.data.data.familyFontOptions ?? "",
                        fontSize: props.data.data.fontSizeOptions ?? "",
                      }}
                    >
                      {auth.userName}
                    </span>
                    <button
                      className="header-options-button-register"
                      style={{
                        fontFamily: props.data.data.familyFontOptions ?? "",
                        fontSize: props.data.data.fontSizeOptions ?? "",
                      }}
                      onClick={handleLogout}
                    >
                      {props.data.data.textButtonLogout ?? "Cerrar Sesión"}
                    </button>
                  </div>
                ) : (
                  <div className="header-options-login-register-container">
                    <input
                      type="text"
                      className="header-options-input-login"
                      placeholder={props.data.data.textInputUser}
                      onChange={onChangeUserEmail}
                      value={userEmail}
                      style={{
                        fontFamily: props.data.data.familyFontOptions ?? "",
                        fontSize: props.data.data.fontSizeOptions ?? "",
                      }}
                    />
                    <input
                      type="password"
                      className="header-options-input-login"
                      placeholder={props.data.data.textInputPassword}
                      id="header-input-password"
                      onChange={onChangeUserPassword}
                      value={userPassword}
                      style={{
                        fontFamily: props.data.data.familyFontOptions ?? "",
                        fontSize: props.data.data.fontSizeOptions ?? "",
                      }}
                    />
                    <button
                      className="header-options-button-register"
                      style={{
                        fontFamily: props.data.data.familyFontOptions ?? "",
                        fontSize: props.data.data.fontSizeOptions ?? "",
                      }}
                      onClick={() => {
                        handleLoginUser(auxUserEmail.email, auxUserPassword.password);
                      }}
                    >
                      {props.data.data.textButtonIngresarDesktop}
                    </button>
                  </div>
                )
              ) : (
                ""
              )
            }
          </div>
          
        </div>
      </div>
      {/*menu en el footer */}
      <div
        id="header-footer-mobile-id"
        className="header-footer-mobile"
        style={{
          backgroundColor: "#0f1c2d"
        }}
      >
        
      </div>
    </header>
  );
};
