//import { Icon } from "@iconify/react"
import PhoneInput from "react-phone-input-2";
import { useState, useEffect } from "react";
import puntoApi from "../custom/axios/js/axios";
import { Loading } from "./loading";
import {SimpleraMesage} from "../custom/simplera/js/simpleraJs"
export const FooterGeogart = (props) => {
    const detectScrollFooterGeogart = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len));
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemImgFooter=document.getElementById("footer-geogart-img-footer");
        let elemImgLogo=document.getElementById("footer-geogart-img-logo");
        if(!elemImgFooter.classList.contains("animation_showed")){
            if(isVisible){
                elemImgFooter.classList.remove("animate__fadeOut");
                elemImgFooter.classList.add("animate__fadeInUp");
                elemImgLogo.classList.remove("animate__fadeOut");
                elemImgLogo.classList.add("animate__fadeInUp");
                elemImgFooter.classList.add("animation_showed");
            }else{
                elemImgFooter.classList.remove("animate__fadeInUp");
                elemImgFooter.classList.add("animate__fadeOut");
                elemImgLogo.classList.remove("animate__fadeInUp");
                elemImgLogo.classList.add("animate__fadeOut");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollFooterGeogart);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollFooterGeogart);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState([]);
    const [city, setCity] = useState([]);
    const [cityFiltered, setCityFiltered] = useState([]);
    const [countrySelected, setCountrySelected] = useState("");
    const [citySelected, setCitySelected] = useState("");
    const [comment, setComment] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const onChangeName = (event) => {
        setName(event.target.value);
    }
    const onChangeLastName = (event) => {
        setLastName(event.target.value);
    }
    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const onChangePhone = (event) =>{
        setPhone(event);
    }
    const onChangeCity = (event) => {
        setCitySelected(event.target.value);
    };
    const onChangeCountry = (event) => {
        const selectedCountry = event.target.value;
        setCountrySelected(selectedCountry);
        const filteredCities = city.filter((c) => c.countryId === selectedCountry);
        setCityFiltered(filteredCities);
    };  
    const onChangeComment = (event) => {
        setComment(event.target.value);
    }
    
    useEffect(() => {
    
        puntoApi.get("/camp/filters/v1")
        .then((response) => {
            // console.log("Datos Respuesta /camp/filters/v1",response.data);
            const responseData = response.data?.data[0]; // Accede al primer elemento del array
            setCity(responseData?.city || []);
            setCountry(responseData?.country || []);
        })
        .catch((err) => {
            console.error("Error al obtener los datos:", err);
        });

    }, []);
    
    const handleSendMail = (e) => {
        e.preventDefault();
        setIsLoading(true);
        SimpleraMesage.send(
            props.data.orgId,
            email,
            name,
            lastName,
            phone,
            countrySelected,
            citySelected,
            comment,
        ).then(
            (result) => {
                alert(props.data.data.sendOkMessage);
                setIsLoading(false);
                //clearState()
            },
            (error) => {
                alert(`Error al enviar el mensaje: ${error}`);
                setIsLoading(false);
                console.log(error)
            }
        )
    }
    return (
        <div id={props.data ? props.data.href.substring(1, props.data.href.len) : ''} className='footer-geogart'
            style={{
                backgroundColor: props.data.data.backgroundcolor ?? ""
            }}
        >
            { isLoading ? <Loading/> : ""}
            <div className="footer-geogart-container">
                <div className="footer-geogart-container-up-part"
                    style={{
                        backgroundImage: `url(${props.data.data.backgroundcolorImgContact})`,
                        backgroundSize: "cover"
                    }}
                >
                    <div className="footer-geogart-container-up-part-components">
                        <div className="footer-geogart-container-up-part-title">
                            <h3
                                className="footer-geogart-title-container-title"
                                style={{
                                    fontFamily: props.data.data.fontFamilyTitle ?? "",
                                    fontSize: props.data.data.fontSizeTitle ?? "",
                                    color: props.data.data.colorTitle ?? ""
                                }}
                            >
                                {props.data.data.titleContact}
                            </h3>
                            <p
                                className="footer-geogart-title-container-paragraph"
                                dangerouslySetInnerHTML={{
                                    __html: props.data.data.paragraphContact
                                    ? props.data.data.paragraphContact
                                    : "",
                                }}
                                style={{
                                    fontFamily: props.data.data.fontFamilyParagraph ?? "",
                                    fontSize: props.data.data.fontSizeParagraph ?? ""
                                }}
                            />
                            <br/>
                            <form onSubmit={(e)=> handleSendMail(e)} className="footer-geogart-form">
                                <div className="register-container-two-input">
                                    <input
                                        type="text"
                                        placeholder={props.data.data.placeHolderName}
                                        className="register-input-half-width-left register-input-color-placeholder footerGeogart-input"
                                        id="nameFormFooter"
                                        name="name"
                                        value={name}
                                        required
                                        onChange={onChangeName}
                                    />
                                    <input
                                        type="text"
                                        placeholder={props.data.data.placeHolderLastName}
                                        className="register-input-half-width-right register-input-color-placeholder register-input-half-width-input footerGeogart-input"
                                        id="lastNameFormFooter"
                                        name="lastName"
                                        value={lastName}
                                        onChange={onChangeLastName}
                                        required
                                    />
                                </div>
                                <div className="register-container-two-input">
                                    <input
                                        type="text"
                                        placeholder={props.data.data.placeHolderEmail}
                                        className="register-input-half-width-left register-input-color-placeholder footerGeogart-input"
                                        id="emailFormFooter"
                                        name="email"
                                        value={email}
                                        onChange={onChangeEmail}
                                        required
                                    />
                                    <PhoneInput
                                        placeholder={props.data.data.placeHolderPhone}
                                        value={phone}
                                        className="register-input-half-width-right register-input-color-placeholder footerGeogart-input"
                                        onChange={onChangePhone}
                                        inputProps={{
                                            required: true,
                                            autoFocus: false,
                                            name: "phone"
                                        }}
                                        country={props.data.data.countryIdDefault ?? "cl"}
                                        preferredCountries={props.data.data.countryPreferredDefault ?? ["cl", "ar"]}
                                    />
                                </div>
                                <div className="register-container-two-input">
                                    <select
                                        placeholder={props.data.data.placeHolderCountry}
                                        className="register-input-half-width-left register-input-color-placeholder register-input-half-width-select footerGeogart-input"
                                        name="country"
                                        id="countryFormFooter"
                                        onChange={onChangeCountry}
                                        required
                                    >
                                        <option
                                            value=""
                                            placeholder={props.data.data.placeHolderCountry}
                                        >
                                            {props.data.data.placeHolderCountry}
                                        </option>
                                        {country?.map((country,key)=>(
                                            <option value={country.countryId} key={key}>{country.countryName}</option>
                                        ))}
                                    </select>
                                    <select
                                        placeholder={props.data.data.placeHolderCity}
                                        className="register-input-half-width-right register-input-color-placeholder register-input-half-width-select footerGeogart-input"
                                        name="city"
                                        id="cityFormFooter"
                                        onChange={onChangeCity}
                                        required
                                    >
                                        <option
                                            value=""
                                            placeholder={props.data.data.placeHolderCity}
                                        >
                                            {props.data.data.placeHolderCity}
                                        </option>
                                        {cityFiltered?.map((city,key) => (
                                            <option value={city.cityId} key={key} >{city.cityName} </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="">
                                    <textarea
                                        type="text"
                                        rows="5"
                                        placeholder={props.data.data.placeHolderComment}
                                        className="register-input-all-width register-input-color-placeholder register-input-text-area footerGeogart-input"
                                        id="commentFormFooter"
                                        name="comment"
                                        value={comment}
                                        required
                                        onChange={onChangeComment}
                                    ></textarea>
                                </div>
                                <div className="footer-geogart-button-container">
                                    <button
                                        className="our-achievments-items-item-button-container-button"
                                        style={{
                                            fontFamily: props.data.data.fontFamilyButton ?? "",
                                            fontSize: props.data.data.fontSizebutton ?? ""
                                        }}
                                    >
                                        {props.data.data.buttonTitle}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="footer-geogart-container-down-part">
                    <div className="footer-geogart-container-down-part-components">
                        <div className="footer-geogart-container-img-logo">
                            <img
                                alt={props.data.data.title}
                                src={props.data.data.imgLogo}
                                className="img-fluid footer-geogart-img-logo animate__animated animate__fadeInUp footer-geogart-animation-duration"
                                id={"footer-geogart-img-logo"}
                                style={{
                                    maxHeight: props.data.data.maxHeightimgLogo ?? ""
                                }}
                            />
                            <div className="footer-geogart-img-logo-info">
                                <p>{props.data.data.address}</p>
                                <p>{props.data.data.phone}</p>
                                <p>{props.data.data.email}</p>
                            </div>
                        </div>
                        <div className="footer-geogart-container-info-img-footer">    
                            <img
                                alt={props.data.data.title}
                                src={props.data.data.imgFooter}
                                className="img-fluid footer-geogart-img-footer animate__animated animate__fadeInUp footer-geogart-animation-duration"
                                id={"footer-geogart-img-footer"}
                                style={{
                                    maxHeight: props.data.data.maxHeightimgFooter ?? ""
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="footer-geogart-container-last-part"></div>
            </div>
        </div>
    )
}

