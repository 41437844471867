// 05/05/2023 v0.0.1 - Enmanuelle
import React from "react";
import { useEffect } from "react";

export const TwoSectionDetail = (props) => {
    const detectScrollTwoSectionDetail = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len)+props.data.order.toString());
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemLeft=document.getElementById("twoSectionDetail-leftSide");
        let elemRight=document.getElementById("twoSectionDetail-rightSide");
        if(!elemLeft.classList.contains("animation_showed")){
            if(isVisible){
                elemLeft.classList.remove("animate__fadeOutLeft");
                elemLeft.classList.add("animate__fadeInLeft");
                elemRight.classList.remove("animate__fadeOutLeft");
                elemRight.classList.add("animate__fadeInLeft");
                elemLeft.classList.add("animation_showed");
            }else{
                elemLeft.classList.remove("animate__fadeInLeft");
                elemLeft.classList.add("animate__fadeOutLeft");
                elemRight.classList.remove("animate__fadeInLeft");
                elemRight.classList.add("animate__fadeOutLeft");
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollTwoSectionDetail);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollTwoSectionDetail);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section
            id={props.data ? (props.data.href.substring(1, props.data.href.len)+props.data.order.toString()) : ""}
            className="twoSectionDetail"
        >
            <div className="twoSectionDetail-container">
                <div
                    className="twoSectionDetail-leftSide animate__animated animate__fadeInLeft twoSectionDetail-animation-duration"
                    id="twoSectionDetail-leftSide"
                >
                    <div>
                        <img
                            alt={props.data.data.title}
                            src={props.data.data.src}
                            className="img-fluid twoSectionDetail-leftSide-img"
                            style={{
                                maxHeight: props.data.data.maxHeightBigIcon ?? ""
                            }}
                        />
                        <h2
                            className="twoSectionDetail-title"
                        >
                            {props.data.data.title}
                        </h2>
                        <h4
                            className="twoSectionDetail-subTitle"
                            dangerouslySetInnerHTML={{
                                __html: props.data.data.subTitle
                                ? props.data.data.subTitle
                                : "",
                            }}
                        />
                    </div>
                    <div className="twoSectionDetail-itemsIcons">
                        {
                            props.data.data.itemsIcons.map((item,i)=>(
                                <div className="twoSectionDetail-itemsIcons-item" key={"twoSectionDetail-itemsIcons-item"+i.toString()}>
                                    <img
                                        alt={item.title}
                                        src={item.src}
                                        className="img-fluid twoSectionDetail-itemsIcons-item-icon"
                                        style={{
                                            maxHeight: props.data.data.maxHeightIcons ?? ""
                                        }}
                                    />
                                    <h4
                                        className="twoSectionDetail-itemIcons-item-title"
                                        dangerouslySetInnerHTML={{
                                            __html: item.title
                                            ? item.title
                                            : "",
                                        }}
                                    /> 
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div
                    className="twoSectionDetail-rightSide animate__animated animate__fadeInLeft twoSectionDetail-animation-duration"
                    id="twoSectionDetail-rightSide"
                >
                    {
                        props.data.data.itemsList.map((item,i)=>(
                            <div className={"twoSectionDetail-itemsList-item twoSectionDetail-itemsList-item"+i.toString()} key={"twoSectionDetail-itemsList-item"+i.toString()}>
                                <div className="twoSectionDetail-itemsList-item-icon-container">
                                    <img
                                        alt="img-arrow-icon"
                                        className="img-fluid"
                                        src={props.data.data.imgIconArrow}
                                        style={{
                                            maxHeight: props.data.data.maxHeightImgIconArrow ?? ""
                                        }}
                                    />
                                </div>
                                <div className={"twoSectionDetail-itemsList-item-info"
                                                + (props.data.data.itemsList.length - 1 === i ? " twoSectionDetail-itemsList-item-info-last" : "")}>
                                    <h4 className="twoSectionDetail-itemsList-item-title">{item.title}</h4>
                                    {
                                        item.list.map((d,j)=>(
                                            <div key={"twoSectionDetail-itemsList-item-list"+i.toString()+j.toString()}>
                                                <p
                                                    className={"twoSectionDetail-itemsList-item-list-text"+
                                                    (props.data.data.itemsList.length -1 === i ? " twoSectionDetail-itemsList-item-list-text-last-item": (j === item.list.length -1 ? " twoSectionDetail-itemsList-item-list-text-last": ""))}
                                                >
                                                    {d.text}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
};
