// 30/03/2023 v0.0.1 - Enmanuelle
import React from "react";
//import { useEffect } from "react";
//import { useState } from "react";
export const MaintainPage = (props) => {
  return (
    <section
      id={props.data ? props.data.href.substring(1, props.data.href.len) : ""}
      style={{
        marginBottom: props.data.data.marginTBottom ?? "",
        marginTop: "80px",
        paddingBottom: "0px",
      }}
    >
        <div class="imgWithMessage-container">
            {
            <img
                src={props.data.data.src}
                alt={props.data.data.title}
                class="img-fluid"
                style={{
                maxHeight: "1200px",
                objectFit: "cover",
                }}
            />
            }
        </div>
        <h2
            class="animate__animated animate__fadeIn maintain-page-centered-title"
            id="img-with-message-title"
            style={{
                color: props.data.data.colorTitle ? props.data.data.colorTitle : "",
                fontSize: props.data.data.fontSizeTitle ?? "",
                textAlign: "center"
            }}
            >
            {props.data.data.title}
        </h2>
    </section>
  );
};
