import React, {/* Component,*/ useEffect, useState } from "react";
//import Popup from "reactjs-popup";
import { Link, useNavigate} from "react-router-dom";

import { Header } from "./header";
import data from "../data/data.json";
import puntoApi from "../custom/axios/js/axios";
import usePublications from "../hook/usePublication";
const filters = JSON.parse(localStorage.getItem("filters"));
export const PublicationsProperties = (props) => {
  const navigate = useNavigate();
  const dataProps = data.pages.filter((e) => e.pageName === "Publication")[0];
  //Declare Variable

  const { setPublicationProvider } = usePublications();
  //const [viewPublication, setViewPublication] = useState([]);
  const [publications, setPublications] = useState([]);
  const [countries, setCountry] = useState();
  const [cities, setCity] = useState([]);
  const [cityFiltered, setCityFiltered] = useState([]);
  const [operationType, setOperationType] = useState([]);
  const [/*status*/, setStatus] = useState([]);
  const [location, setLocation] = useState([])

  const [countryApi, setCountryApi] = useState(filters?.country || "");
  const [statusApi, /*setStatusApi*/] = useState(filters?.status || "");
  const [operationApi, setOperationApi] = useState(filters?.operation || "");
  const [rangoMin, setRangoMin] = useState(filters?.rango || null);
  // const [rangoMax, setRangoMax] = useState("");
  const [nameFilter, setNameFilter] = useState(filters?.name || "");
  const [zoneFilter, setZoneFilter] = useState(filters?.zone || "");
  const [citySelected, setCitySelected] = useState(filters?.city || "");
  const [locationSelected, setLocationSelected] = useState(filters?.location || "");

  const [publicationsImportant, setPublicationsImportant] = useState([]);
  const [publicationsNoImportant, setPublicationsNoImportant] = useState([]);
  const [alreadySearch, setAlreadySearch] = useState(false);

  useEffect(()=>{
    setTimeout(()=>{
      if(!localStorage.auth){
          navigate("/");
      }
    },0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  //Formato Miles a los numeros
  const numberFormat = function (number) {
    return new Intl.NumberFormat("de-DE").format(number);
  };
  //onChange Filters
  const onChangeCountry = (event) => {
    setCountryApi(event.target.value);
    setCityFiltered(
      cities.filter((city) => city.countryId === event.target.value)
    );
  };
  const onChangeCity = (event) => {
    if (!event.target.value) {
      setCitySelected("");
    } else {
      setCitySelected(event.target.value);
    }
  };
  const onChangeOperation = (event) => {
    setOperationApi(event.target.value);
  };
  const onChangeNameFilter = (event) => {
    setNameFilter(event.target.value);
  };
  const onChangeZoneFilter = (event) => {
    setZoneFilter(event.target.value);
  };
  const onChangeLocation = (event) => {
    setLocationSelected(event.target.value);
  };
  const onChangeRangoMin = (event) => {
    if (!event.target.value) {
      setRangoMin(null);
    } else {
      setRangoMin(event.target.value);
    }
  };
  

  //function operations

  // const filterM2 = (rangeMin) => {
  //   console.log(rangeMin);
  //   if (rangeMin > 0) {
  //     // setViewPublication(
  //     //   viewPublication?.filter(
  //     //     (publication) => publication.adM2From >= rangeMin
  //     //   )
  //     // );
  //     viewPublication= publications?.filter(
  //       (publication) => publication.adM2From >= rangeMin
  //     )
  //   } else {

  //     viewPublication =publications;
  //   }
  // };
  const handleCleanFilter = () => {
    setAlreadySearch(false);
    document.getElementById("countrySelect").value = "";
    document.getElementById("citySelect").value = "";
    document.getElementById("operationSelect").value = "";
    setNameFilter("");
    setZoneFilter("");
    setRangoMin(0);
    localStorage.removeItem("filters");
    setPublicationsImportant([]);
    setPublicationsNoImportant([]);
    // setViewPublication(publications);
  };
  const handleSearch = () => {
    let publicationsFilter= publications?.filter((publication) =>
        (nameFilter==='' || publication.adName
          .toLowerCase()
          .includes(nameFilter.toLocaleLowerCase())) && 
          (rangoMin === 0 || rangoMin === null || (publication.adM2From <= rangoMin && publication.adM2To>=rangoMin) ) &&
          (zoneFilter==='' || publication.adZone.toLowerCase().includes(zoneFilter.toLocaleLowerCase())) &&
          (locationSelected === ''||(locationSelected==='mall'&& publication.adInMall===true)||(locationSelected==='street'&& publication.adInStreet===true))
    );
    setAlreadySearch(true);
    setPublicationsImportant(publicationsFilter.filter(publication => publication.adIsImportant));
    setPublicationsNoImportant(publicationsFilter.filter(publication => !publication.adIsImportant));
  }

  useEffect(() => {
    // const urlPublication = `/ads/broker/v1?countryId=${countryApi}&operationTypeId=${operationApi}&statusId=${statusApi}`;
    // async function fetchApi() {
    //   const response = await puntoApi.get(urlPublication);
    //   setPublications(response.data.data);
    // }
    // fetchApi();

    puntoApi
      .get("/camp/filters/v1")
      .then((response) => {
        // console.log(response);
        const responseData = response.data?.data[0];
        setCity(responseData?.city || []);
        setCountry(responseData?.country || []);
        setOperationType(responseData?.operationType || []);
        setStatus(responseData?.statusApply || []);
        setLocation(responseData?.location || []);
      })
      .catch((err) => {
        console.error("Error al obtener los datos:", err);
      });
  }, []);
  useEffect(() => {
    const urlPublication = `/camp/broker/v1?countryId=${countryApi}&operationTypeId=${operationApi}&cityId=${citySelected}`;

    let authLocalAux = JSON.parse(localStorage.getItem("auth"));
    if(!authLocalAux){
      return; 
    }
    const token = authLocalAux?.token;

    async function fetchApi() {
      // console.log(urlPublication);
      const response = await puntoApi.get(urlPublication, token);
      // setViewPublication(response.data.data);
      // console.log(response);
      // console.log('response', response.data.data)
      setPublications(response.data.data);
      /*setPublicationsImportant(publicationsFilter.filter((publication)=>(publication.adIsImportant)));
      setPublicationsNoImportant(publicationsFilter.filter((publication)=>(!publication.adIsImportant))); */
    }
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryApi, operationApi, statusApi]);
  // console.log(publications);
  return (
    <div
      className="register-container"
      style={{
        backgroundImage: "url('"+dataProps.data.backgroundImg+"')",
      }}
    >
      {!props.data ? (
        <Header data={data.pages.filter((e) => e.pageName === "Header")[0]} />
      ) : (
        <div />
      )}
      <div className="publication-container">
        <div className="section-title-prop">
          {
            dataProps.data.imgIcon ?
            <img
              alt="title-img"
              src={dataProps.data.imgIcon}
              className="img-fluid publicacions-title-img"
              style={{
                maxHeight: dataProps.data.maxHeightIcon ?? ""
              }}
            />
            :
            ""
          }
          <h2 className="publications-title">
            {props.data ? props.data.data.titlePage : dataProps.data.titlePage}
          </h2>
        </div>
        <div className="filters__sections-container">
          <div className="filters__section">
            <div className="filters__subsection">
              <select
                className="filters__toggle btn-filter publication-filter-first"
                id="countrySelect"
                onChange={onChangeCountry}
                
              >
                <option className="cointainerSelection" key="key2" value="">
                  {props.data
                    ? props.data.data.firstSelect
                    : dataProps.data.firstSelect}
                </option>
                {countries?.map((country, key) => (
                  <option
                    className="cointainerSelection"
                    key={"cointainerSelection-firstSelect" + key.toString()}
                    value={country.countryId}
                  >
                    {" "}
                    {country.countryName}{" "}
                  </option>
                ))}
              </select>
              <select
                className="filters__toggle btn-filter publication-filter-second"
                id="citySelect"
                onChange={onChangeCity}
              >
                <option className="cointainerSelection" key="key1" value="">
                  {props.data
                    ? props.data.data.secondSelect
                    : dataProps.data.secondSelect}
                </option>
                {cityFiltered?.map((city, key) => (
                  <option
                    className="cointainerSelection"
                    key={"cointainerSelection-secondSelect" + key.toString()}
                    value={city.cityId}
                  >
                    {" "}
                    {city.cityName}{" "}
                  </option>
                ))}
              </select>
            </div>
            <div className="filters__subsection">
              <select
                className="filters__toggle btn-filter publication-filter-first"
                id="operationSelect"
                onChange={onChangeOperation}
              >
                <option className="cointainerSelection" key="key3" value="">
                  {props.data
                    ? props.data.data.thirdSelect
                    : dataProps.data.thirdSelect}
                </option>
                {operationType?.map((operationType, key) => (
                  <option
                    className="cointainerSelection"
                    key={"cointainerSelection-thirdSelect" + key.toString()}
                    value={operationType.operationTypeId}
                  >
                    {" "}
                    {operationType.operationTypeName}{" "}
                  </option>
                ))}
              </select>
              <select
                className="filters__toggle btn-filter publication-filter-last"
                id="locationSelect"
                onChange={onChangeLocation}
              >
                <option className="cointainerSelection" key="key3" value="">
                  {props.data
                    ? props.data.data.fourthSelect
                    : dataProps.data.fourthSelect}
                </option>
                {location?.map((location, key) => (
                  <option
                    className="cointainerSelection"
                    key={"cointainerSelection-fourthSelect" + key.toString()}
                    value={location.locationId}
                  >
                    {" "}
                    {location.locationName}{" "}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {
            !dataProps.data.showLastRow ?
            ""
            :
            <div className="filters__section filters__subsection-publications-container">
            <div className="filters__subsection filters__subsection-publications">
              <input
                className="filters__toggle input-filter"
                type="number"
                name="minM2"
                placeholder={dataProps.data.firstTextfield}
                value={rangoMin}
                onChange={onChangeRangoMin}
              />
              {/* <label>Rango</label> */}
              <input
                className="filters__toggle input-filter publication-filter-second publication-filter-second-textfield"
                placeholder={dataProps.data.secondTextfield}
                onChange={onChangeNameFilter}
                value={nameFilter}
              />
            </div>
            <div className="filters__subsection filters__subsection-publications">
              <input
                className="filters__toggle input-filter publication-filter-last-input"
                placeholder={dataProps.data.thirdTextfield}
                onChange={onChangeZoneFilter}
                value={zoneFilter}
              />
            </div>
            </div>
          }
          
        </div>
        <div className="publications-buttons">
          <button
            className="publications-buttons-button publications-buttons-button-search publications-buttons-button-desktop"
            onClick={() => handleSearch()}
          >
            {props.data
              ? props.data.data.buttonSearch
              : dataProps.data.buttonSearch}
          </button>
          <button
            className="publications-buttons-button publications-buttons-button-clean publications-buttons-button-desktop"
            onClick={() => handleCleanFilter()}
          >
            {props.data
              ? props.data.data.buttonClean
              : dataProps.data.buttonClean}
          </button>

          <button
            className="publications-buttons-button publications-buttons-button-clean publications-buttons-button-mobile publications-buttons-button-mobile-clean"
            onClick={() => handleCleanFilter()}
          >
            {props.data
              ? props.data.data.buttonClean
              : dataProps.data.buttonClean}
          </button>
          <button
            className="publications-buttons-button publications-buttons-button-search publications-buttons-button-mobile publications-buttons-button-mobile-search"
            onClick={() => handleSearch()}
          >
            {props.data
              ? props.data.data.buttonSearch
              : dataProps.data.buttonSearch}
          </button>
        </div>
        <div className="publications-container">
          <div className="publications-container-important">
            {publicationsImportant?.map((publication, index) => (
                <div className="publications-container-important-items">
                  <Link
                    className={"publication-wrap-box "}
                    key={"publication-wrap-box" + index.toString()}
                    to={`/publication/details?adId=${publication.adId}`}
                    onClick={() => setPublicationProvider(publication.adId)}
                  >
                    <div className="publication-wrap-box__title">
                      <div className="publication-wrap-box__title-img">
                        <img
                          src={publication.operationTypeName==="Compra" ? dataProps.data.iconBuy : dataProps.data.iconArriend}
                          alt="icon-publication"
                          className="img-fluid"
                          style={{
                            maxHeight: dataProps.data.maxHeightIconPublications ?? ""
                          }}
                        />
                      </div>
                      <div>
                        <p className="publication-wrap-box__title-operation">{publication.operationTypeName}</p>
                        <h4 className="publication-wrap-box__title-adName">{publication.adName}</h4>
                        <span className="location">
                          <span className="tolltipster">
                            {publication.countryId === "CL" ? (
                              <span
                                className="location-flag"
                                style={{
                                  backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/cl_mini.png"})`,
                                }}
                              ></span>
                            ) : publication.countryId === "AR" ? (
                              <span
                                className="location-flag"
                                style={{
                                  backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/ar_mini.png"})`,
                                }}
                              ></span>
                            ) : null}
                          </span>
                          {publication.countryName}, {publication.cityName} -{" "}
                          {numberFormat(publication.adM2From)}m² a{" "}
                          {numberFormat(publication.adM2To)}m² -{" "}
                          {publication.adZone}
                        </span>
                      </div>
                    </div>
                  </Link>
                  {
                    index!==publicationsImportant.length-1 ?
                    <div className="publication-wrap-box-separator"></div>
                    :
                    ""
                  }
                </div>
            ))}
          </div>
          <div className="publications-container-noImportant">
            {publicationsNoImportant?.map((publication, index) => (

              <div className={"publications-container-noimportant-items " + (index % 2 !== 0 ? "publications-container-noimportant-items-par": "")}>
                <Link
                  className={"publication-wrap-box-no-important"}
                  key={"publication-wrap-box" + index.toString()}
                  to={`/publication/details?adId=${publication.adId}`}
                  onClick={() => setPublicationProvider(publication.adId)}
                >
                  <div className="publication-wrap-box__title">
                    <div className="publication-wrap-box__title-img">
                      <img
                        src={publication.operationTypeName==="Compra" ? dataProps.data.iconBuyNoImportant : dataProps.data.iconArriendNoImportant}
                        alt="icon-publication"
                        className="img-fluid"
                        style={{
                          maxHeight: dataProps.data.maxHeightIconPublications ?? ""
                        }}
                      />
                    </div>
                    <div>
                      <p className="publication-wrap-box__title-operation publication-wrap-box__title-operation-noImportant">{publication.operationTypeName}</p>
                      <h4 className="publication-wrap-box__title-adName publication-wrap-box__title-adName-noImportant">{publication.adName}</h4>
                      <span className="location">
                        <span className="tolltipster">
                          {publication.countryId === "CL" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/cl_mini.png"})`,
                              }}
                            ></span>
                          ) : publication.countryId === "AR" ? (
                            <span
                              className="location-flag"
                              style={{
                                backgroundImage: `url(${"https://www.getonbrd.com/assets/flags/ar_mini.png"})`,
                              }}
                            ></span>
                          ) : null}
                        </span>
                        {publication.countryName}, {publication.cityName} -{" "}
                        {numberFormat(publication.adM2From)}m² a{" "}
                        {numberFormat(publication.adM2To)}m² -{" "}
                        {publication.adZone}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          {
            alreadySearch ?
              <div>
                {
                  publicationsImportant.length === 0 && publicationsImportant.length === 0 ?
                  <p
                    className="postulation-not-found"
                    key={""}
                  >
                    <div className="postulation-not-found-container">
                      <p className="postulation-not-found-text">{dataProps.data.nonePostulation ?? "No se encontró ninguna postulación."}</p>
                    </div>
                  </p>
                  :
                  ""
                }
              </div>
            :
            <div className="postulation-not-found-container">
              <p className="postulation-not-found-text">{dataProps.data.noSearchYet ?? "Ingrese los filtros que desee para ver sus postulaciones."}</p>
            </div>
          }          
        </div>
      </div>
    </div>
  );
};
