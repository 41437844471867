/*
ver íconos en: https://icon-sets.iconify.design/bx/timer/
*/
//import { Icon } from "@iconify/react"
//import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const OurAchievments = (props) => {
    const navigate = useNavigate();
    const detectScrollOurAchievments = () => {
        let elem=document.getElementById(props.data.href.substring(1, props.data.href.len));
        if(!elem){
            return;
        }
        let rectElem=elem.getBoundingClientRect();
        let isVisible=rectElem.top < window.innerHeight * 0.8 && rectElem.bottom >= window.innerHeight * 0.2;
        let elemTitleUp=document.getElementById("our-achievments-section-title");
        let elemTextBelow=document.getElementById("our-achievments-text-below");
        if(!elemTitleUp.classList.contains("animation_showed")){
            if(isVisible){
                elemTextBelow.classList.remove("animate__fadeOutLeft");
                elemTextBelow.classList.add("animate__fadeInLeft");
                elemTitleUp.classList.remove("animate__fadeOut");
                elemTitleUp.classList.add("animate__fadeIn");
                elemTitleUp.classList.add("animation_showed");
                for(let i=0;i<props.data.data.items.length;i++){
                    let elemTitle=document.getElementById("our-achievments-items-item-title " + i.toString());
                    let elemBox=document.getElementById("our-achievments-items-item" + i.toString());
                    let elemInsideBox=document.getElementById("our-achievments-items-item-text-part" + i.toString());
                    elemTitle.classList.remove("animate__fadeOutLeft");
                    elemTitle.classList.add("animate__fadeInLeft");
                    elemBox.classList.remove("animate__fadeOut");
                    elemBox.classList.add("animate__fadeInUp");
                    elemInsideBox.classList.remove("animate__fadeOutLeft");
                    elemInsideBox.classList.add("animate__fadeInLeft");
                }
            }else{
                elemTextBelow.classList.remove("animate__fadeInLeft");
                elemTextBelow.classList.add("animate__fadeOutLeft");
                elemTitleUp.classList.remove("animate__fadeIn");
                elemTitleUp.classList.add("animate__fadeOut");
                for(let i=0;i<props.data.data.items.length;i++){
                    let elemTitle=document.getElementById("our-achievments-items-item-title " + i.toString());
                    let elemBox=document.getElementById("our-achievments-items-item" + i.toString());
                    let elemInsideBox=document.getElementById("our-achievments-items-item-text-part" + i.toString());
                    elemTitle.classList.remove("animate__fadeInLeft");
                    elemTitle.classList.add("animate__fadeOutLeft");
                    elemBox.classList.remove("animate__fadeInUp");
                    elemBox.classList.add("animate__fadeOut");
                    elemInsideBox.classList.remove("animate__fadeInLeft");
                    elemInsideBox.classList.add("animate__fadeOutLeft");
                }
            }
        }
    }
    useEffect(()=>{
        window.addEventListener("scroll", detectScrollOurAchievments);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    useEffect(()=>{
        return () => {
            window.removeEventListener("scroll", detectScrollOurAchievments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    return (
        <section id={props.data ? (props.data.href.substring(1, props.data.href.len)) : ''} className="our-achievments"
            style={{
                backgroundColor: props.data.data.backgroundcolor ? props.data.data.backgroundcolor : "",
            }}
        >
            <div className="our-achievments-container">
                <div
                    className="our-achievments-section-title animate__animated animate__fadeIn our-achievments-animation-duration"
                    id="our-achievments-section-title"
                >
                    <img
                        alt="our-achievments-section-title-img"
                        className="img-fluid our-achievments-section-title-img"
                        src={props.data.data.iconTop}
                        style={{
                            maxHeight: props.data.data.maxHeightIconTop ?? ""
                        }}
                    />
                    <h2
                        className="our-achievments-section-title-title"
                        dangerouslySetInnerHTML={{
                            __html: props.data.data.title
                            ? props.data.data.title
                            : "",
                        }}
                        style={{
                            fontFamily: props.data.data.fontFamilyTitle ?? "",
                            fontSize: props.data.data.fontSizeTitle ?? "",
                        }}
                    />
                </div>
                <div className="our-achievments-items">
                    {props.data ? props.data.data.items.map((d, i) => (
                    <div
                        className={"our-achievments-items-item animate__animated animate__fadeInUp our-achievments-animation-duration" +
                                    (i % 2 === 0 ? " our-achievments-items-item-separation-left": " our-achievments-items-item-separation-right")}
                        key={`${d.title}-${i}`}
                        id={"our-achievments-items-item" + i.toString()}
                        style={{
                            backgroundColor: props.data.data.backgroundcolorItem ? props.data.data.backgroundcolorItem : "",
                            color: props.data.data.colorTextItem ? props.data.data.colorTextItem : ""
                        }}
                    >
                        <div className="our-archievmentes-items-item-container">
                            <div>
                            <div className="our-achievments-items-item-title-container">
                                <img
                                    alt={d.title}
                                    src={d.imgBackgroundTitle}
                                    className="our-achievments-items-title-container-img"
                                    style={{
                                        filter: props.data.data.filter ?? "",
                                    }}
                                />
                                <h4
                                    id={"our-achievments-items-item-title "+ i.toString()}
                                    className="our-achievments-items-item-title animate__animated animate__fadeInLeft our-achievments-animation-duration"
                                >
                                    <span>{d.title}</span>
                                </h4>
                            </div>
                            <br></br>
                            <div
                                id={"our-achievments-items-item-text-part" + i.toString()}
                                className="our-achievments-items-item-text-part animate__animated animate__fadeInLeft our-achievments-animation-duration"
                            >
                                <p
                                    className="our-achievments-items-item-subtitle"
                                    dangerouslySetInnerHTML={{
                                        __html: d.subTitle
                                        ? d.subTitle
                                        : "",
                                    }}
                                    style={{
                                        fontFamily: d.fontFamilySubTitle ?? "",
                                        fontSize: d.fontSizeSubTitle ?? "",
                                        fontWeight: d.fontWeightSubTitle ?? "100"
                                    }}
                                />
                                <p
                                    id={"our-achievments-items-item-description " + i.toString()}
                                    className="our-achievments-items-item-text"
                                    dangerouslySetInnerHTML={{
                                        __html: d.text
                                        ? d.text
                                        : "",
                                    }}
                                    style={{
                                        fontFamily: d.fontFamilyText ?? "",
                                        fontSize: d.fontSizeText ?? ""
                                    }}
                                />
                            </div>
                            </div>
                            <div className="our-achievments-items-item-button-container">
                                <a href={d.href}>
                            <button
                                className="our-achievments-items-item-button-container-button"
                                onClick={()=>{navigate(d.href)}}
                                style={{
                                    fontFamily: d.fontFamilyButton ?? "",
                                    fontSize: d.fontSizeButton ?? ""
                                }}
                            >
                                {d.buttonText}
                            </button>
                            </a>
                                
                        </div>
                        </div>
                        
                    </div>
                    )) : 'Loading...'}
                </div>
                <br></br>
                <p
                    id={"our-achievments-text-below"}
                    className="our-achievments-text-below animate__animated"
                    style={{
                        color: props.data.data.colorTextBelow ? props.data.data.colorTextBelow : "",
                        fontSize: props.data.data.fontSizeTextBelow ? props.data.data.fontSizeTextBelow : "35px"
                    }}
                >
                    {props.data.data.textBelow ? props.data.data.textBelow : ""}
                </p>
            </div>
        </section>
    )
}