import { /*useState,*/ useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { /*BrowserRouter,*/ Routes, Route } from "react-router-dom";
//import { Navigation } from "./components/navigation";
import { About } from "./components/about";
import { Header } from "./components/header";
import { ImgWithMessage } from "./components/imgWithMessage";
import { Features } from "./components/features";
import { Services } from "./components/services";
import { CommentFb } from "./components/commentFb";
import { Footer } from "./components/footer";
import { HeroCarousel } from "./components/heroCarousel";
import { Counts } from "./components/counts";
import { OurServices } from "./components/ourServices";
import { Checkout } from "./components/checkout";
import { Cta } from "./components/cta";
import { CartShopping } from "./components/CartShopping";
import { Portfolio } from "./components/portfolio";
import { Team } from "./components/team";
import { Contact } from "./components/contact";
import { AttributesFull } from "./components/attributesFull";
import { AttributesWithImage } from "./components/attributesWithImage";
import { ButtonWithArrow } from "./components/buttonWithArrow";
import { OurAchievments } from "./components/ourAchievments";
import { OurAdvices } from "./components/ourAdvices";
import { TextWithImage } from "./components/textWithImage";
import { OurOfferTime } from "./components/ourOfferTime";
import { Clients } from "./components/clients";
import { FooterGeogart } from "./components/footerGeogart";
import { MaintainPage } from "./components/maintainPage";
import { LinesWithContent } from "./components/linesWithContent";
import { GeoInteligencia } from "./components/geoInteligencia";
import { WhatWeDo } from "./components/whatWeDo";
import { FloatingFooter } from "./components/floatingFooter";
import TransferPay from "./components/transferPay";
import data from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { CartProvider } from "./context/CartProvider";
import useAuth from "./hook/useAuth";

import { ProductDetails } from "./components/productDetails";
import { Login } from "./components/Login";
import { Register } from "./components/Register";
import { ContactMobile } from "./components/contactMobile";
import { PostulationsProperties } from "./components/PostulationsProperties";
import { PublicationsProperties } from "./components/PublicationsProperties";
import { PublicationDetails } from "./components/publicationPropertiesDetails";
import { PostulationDetails } from "./components/postulationPropertiesDetails";
import { StatePostulations } from "./components/statePostulations";
import { SendMail } from "./components/ChangePassword/sendMail";
import { TokenPassword } from "./components/ChangePassword/tokenPassword";
import { ChangePassword } from "./components/ChangePassword/changePassword";
//import { AdviceLogout } from "./components/adviceLogout";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  /*const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);*/
  const { /*auth,*/ setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  useEffect(()=>{
    //console.log(sessionStorage.webInfo ? "hay algo" : "no hay nada");
    if(!localStorage.lastDateDataBack){
      // console.log("entra 1");
      localStorage.setItem("lastDateDataBack",new Date());
    }else{
      // console.log("entra 2");
      let fechaActual=new Date();
      if(Date.parse(localStorage.lastDateDataBack) < fechaActual.setHours(fechaActual.getHours()-6)){
        // console.log("entra 3");
        localStorage.setItem("lastDateDataBack",new Date());
        localStorage.removeItem("auth");
        setAuth(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  // useEffect(()=>{
  //   let interval = setInterval(()=>{
  //     if(localStorage.auth){
  //       if(!localStorage.lastDateDataBack){
  //         localStorage.setItem("lastDateDataBack",new Date());
  //       }else{
  //         let fechaActual=new Date();
  //         if(Date.parse(localStorage.lastDateDataBack) < fechaActual.setHours(fechaActual.getHours()-6)){
  //           localStorage.setItem("lastDateDataBack",new Date());
  //           localStorage.removeItem("auth");
  //           setAuth(null);
  //           navigate("/");
  //         }
  //       }
  //     }
  //   },10000);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);
  useEffect(()=>{
    if(localStorage.auth){
      if(location.pathname === "/"){
        navigate("/statePostulations");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  const paginas = [];
  if(data.showMaintainPage){
    for (const item of data.pagesMaintain) {
      //if (item.pageName === "Navigation") {
      //  {/*paginas.push({pagina: <Navigation/>, orden: item.order});*/ }
      //} else
      if (item.pageName === "Header") {
        paginas.push({ pagina: <Header data={item} />, orden: item.order });
      }else if (item.pageName === "ImgWithMessageMaintain") {
        paginas.push({
          pagina: <ImgWithMessage data={item} />,
          orden: item.order,
        });
      }else if(item.pageName === "MaintainPage"){
        paginas.push({
          pagina: <MaintainPage data={item} />,
          orden: item.order,
        });
      }
  }
  }else{
    for (const item of data.pages) {
      //if (item.pageName === "Navigation") {
      //  {/*paginas.push({pagina: <Navigation/>, orden: item.order});*/ }
      //} else
      if (item.pageName === "Header") {
        paginas.push({ pagina: <Header data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "About") {
        paginas.push({ pagina: <About data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Features") {
        paginas.push({ pagina: <Features data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Services") {
        paginas.push({ pagina: <Services data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "CommentFb") {
        paginas.push({ pagina: <CommentFb data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Footer") {
        paginas.push({ pagina: <Footer data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "FooterGeogart") {
        paginas.push({ pagina: <FooterGeogart data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "HeroCarousel") {
        paginas.push({ pagina: <HeroCarousel data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Counts") {
        paginas.push({ pagina: <Counts data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "OurServices") {
        paginas.push({ pagina: <OurServices data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Cta") {
        paginas.push({ pagina: <Cta data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Contact") {
        paginas.push({ pagina: <Contact data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Portfolio") {
        paginas.push({ pagina: <Portfolio data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Team") {
        paginas.push({ pagina: <Team data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "ImgWithMessage") {
        paginas.push({
          pagina: <ImgWithMessage data={item} key={Math.random()}/>,
          orden: item.order,
        });
      } else if (item.pageName === "LinesWithContent") {
        paginas.push({
          pagina: <LinesWithContent data={item} key={Math.random()}/>,
          orden: item.order,
        });
      } else if (item.pageName === "AttributesFull") {
        paginas.push({
          pagina: <AttributesFull data={item} key={Math.random()}/>,
          orden: item.order,
        });
      } else if (item.pageName === "AttributesWithImage") {
        paginas.push({
          pagina: <AttributesWithImage data={item} key={Math.random()}/>,
          orden: item.order,
        });
      } else if (item.pageName === "CartShopping") {
        paginas.push({ pagina: <CartShopping data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "ButtonWithArrow") {
        paginas.push({ pagina: <ButtonWithArrow data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "OurAchievments") {
        paginas.push({ pagina: <OurAchievments data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "OurAdvices") {
        paginas.push({ pagina: <OurAdvices data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "TextWithImage") {
        paginas.push({ pagina: <TextWithImage data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "OurOfferTime") {
        paginas.push({ pagina: <OurOfferTime data={item} key={Math.random()}/>, orden: item.order });
      } else if (item.pageName === "Clients") {
        paginas.push({ pagina: <Clients data={item} key={Math.random()}/>, orden: item.order });
      } /*else if (item.pageName === "Publication") {
        paginas.push({
          pagina: <PublicationsProperties data={item} />,
          orden: item.order,
        });
      } */
      // else if (item.pageName === "Postulations") {
      //   paginas.push({
      //     pagina: <PostulationsProperties data={item} />,
      //     orden: item.order,
      //   });
      // }
  }
  
}




  return (
    
        <CartProvider>
          {/* <div>{paginas.sort((a, b) => a.orden - b.orden).map((e) => e.pagina)}</div> */}
          <Routes>
            <Route
              path="/"
              element={
                <div>
                  {paginas
                    .sort((a, b) => a.orden - b.orden)
                    .map((e) => e.pagina)}
                </div>
              }
            />
            <Route path="/checkout/" element={<Checkout />} />
            <Route path="/checkout/transfer" element={<TransferPay />} />
            <Route path="/productDetails" element={<ProductDetails />} />
            <Route path="/publications" element={<PublicationsProperties />} />
            <Route path="/publication/details" element={<PublicationDetails />}/>
            <Route path="/postulations" element={<PostulationsProperties />} />
            <Route path="/postulation/details" element={<PostulationDetails />}/>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotPassword" element={<SendMail data={data.pages.filter(dat=>dat.pageName==="SendMail")[0]}/>} />
            <Route path="/forgotPassword/tokenPassword" element={<TokenPassword data={data.pages.filter(dat=>dat.pageName==="TokenPassword")[0]}/>} />
            <Route path="/forgotPassword/tokenPassword/changePassword" element={<ChangePassword data={data.pages.filter(dat=>dat.pageName==="ChangePassword")[0]}/>} />
            <Route path="/geoInteligencia" element={<GeoInteligencia />} />
            <Route path="/queHacemos" element={<WhatWeDo />} />
            <Route path="/contacto" element={<ContactMobile />} />
            <Route path="/statePostulations" element={<StatePostulations data={data.pages.filter(dat=>dat.pageName==="StatePostulations")[0]} />} />
          </Routes>
          {/*<AdviceLogout data={JsonData.pages.filter(dat=>dat.pageName==="AdviceLogout")[0]}/>*/}
          <FloatingFooter data={data.pages.filter(dat=>dat.pageName==="FloatingFooter")[0]}/>
        </CartProvider>

  );
};

export default App;
